import axios from "..";
import {
  CREATE_CLIENT_CONTACT,
  UPDATE_CLIENT_CONTACT,
  GET_CLIENT_CONTACT,
  REACTIVATE_CLIENT_CONTACT,
  DEACTIVATE_CLIENT_CONTACT,
  GET_CLIENT_CONTACT_LISTING,
} from "../../resources/constants";

class clientContactService {
  public static async createContact(data: any) {
    return axios.put(CREATE_CLIENT_CONTACT, data);
  }

  public static async updateContact(data: any) {
    return axios.post(UPDATE_CLIENT_CONTACT, data);
  }

  public static async getListing(data: any) {
    return axios.get(
      GET_CLIENT_CONTACT_LISTING + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getContact(id: string) {
    return axios.get(GET_CLIENT_CONTACT + "/" + id);
  }

  public static async deactivate(id: string) {
    return axios.put(DEACTIVATE_CLIENT_CONTACT + "/?clientContactId=" + id, {});
  }

  public static async activate(id: string) {
    return axios.put(REACTIVATE_CLIENT_CONTACT + "/?clientContactId=" + id, {});
  }
}

export default clientContactService;
