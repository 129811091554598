import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import transfereeProfileService from "../../services/TransfereeProfile/TransfereeProfile";
import loginServices from "../../services/Login/login";

interface intialState {
  loading: boolean;
  error: null | string;
  data: any;
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {},
};

export const transfereeProfileDetails = createAsyncThunk(
  "getTransfereeDetails/transfereeProfile",
  async (id: string) => {
    return transfereeProfileService.getTransfereeDetails(id);
  },
);

const transfereeProfileFullfield = (state: any, action: any) => {
  state.loading = false;
  state.data = action.payload?.data;
};

export const sendWelcomeEmail = createAsyncThunk(
  "welcome-email/transfereeProfile",
  async (transfereeId: any) => {
    return loginServices.sendWelcomeEmail(transfereeId);
  },
);

const transfereeProfileSlice = createSlice({
  name: "transfereeProfile",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(
      transfereeProfileDetails.fulfilled,
      transfereeProfileFullfield,
    );
    builder.addCase(sendWelcomeEmail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendWelcomeEmail.pending, (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(
      (action) =>
        action.type.endsWith("transfereeProfile/pending") ||
        action.type.endsWith("transfereeProfile/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default transfereeProfileSlice.reducer;
