import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";
import dashboardService from "../../services/Dashboard/dashboard";
import { randomColorArray } from "../../pages/dashboard/Dashboard.constants.";

export interface DashBoardState {
  ytd?: number;
  ytdAuths?: number;
  ytdActiveFiles?: number;
  authByPolicy?: [];
  spendsByPolicy?: [];
  servicesByPolicy?: [];
  exceptionsByPolicy?: [];
  upcomingVisaExp?: number;
  loading: boolean;
  loadingChart: boolean;
  error?: string;
  loaders: {
    ytd: boolean;
    ytdActiveFiles: boolean;
    ytdAuths: boolean;
    authByPolicy: boolean;
    spendsByPolicy: boolean;
    exceptionsByPolicy: boolean;
    upcomingVisaExp: boolean;
    servicesByPolicy: boolean;
    mapsData: boolean;
    divisions: boolean;
  };
  mapsData: {
    destinationCountries: [];
    originCountries: [];
    destinationStates: [];
    originStates: [];
  };
  divisions: any;
  colors: any;
}

export const initialState: DashBoardState = {
  ytd: 0,
  loading: false,
  error: undefined,
  ytdAuths: 0,
  ytdActiveFiles: 0,
  authByPolicy: [],
  spendsByPolicy: [],
  exceptionsByPolicy: [],
  servicesByPolicy: [],
  upcomingVisaExp: 0,
  loadingChart: false,
  loaders: {
    ytd: false,
    ytdAuths: false,
    ytdActiveFiles: false,
    authByPolicy: false,
    spendsByPolicy: false,
    exceptionsByPolicy: false,
    servicesByPolicy: false,
    upcomingVisaExp: false,
    mapsData: false,
    divisions: false,
  },
  mapsData: {
    destinationCountries: [],
    originCountries: [],
    destinationStates: [],
    originStates: [],
  },
  divisions: [],
  colors: [],
};

const colorsArray: string[] = ["#FF9F38", "#FFDF38", "#52CD9F", "#16AFF4"];

export function getRandomColor(): string {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getYtdSpends = createAsyncThunk(
  "dashboard/getYtdSpends",
  async (filters: any) => {
    return dashboardService.getYtdSpendsApi(filters);
  },
);

export const getYtdAuthorisations = createAsyncThunk(
  "dashboard/YtdAuthorisations",
  async (filters: any) => {
    return dashboardService.getYtdAuthorisationsApi(filters);
  },
);

export const getYtdActiveFiles = createAsyncThunk(
  "dashboard/getYtdActiveFiles",
  async (filters: any) => {
    return dashboardService.getYtdActiveFilesApi(filters);
  },
);

export const getAuthsByPolicy = createAsyncThunk(
  "dashboard/AuthsByPolicy",
  async (filters: any) => {
    return dashboardService.getAuthsByPolicyApi(filters);
  },
);
export const getSpendsByPolicy = createAsyncThunk(
  "dashboard/SpendsByPolicy",
  async (filters: any) => {
    return dashboardService.getSpendsByPolicyApi(filters);
  },
);
export const getExceptionsByPolicy = createAsyncThunk(
  "dashboard/ExcepionsByPolicy",
  async (filters: any) => {
    return dashboardService.getExceptionsByPolicyApi(filters);
  },
);

export const getVisaExpiryPolicy = createAsyncThunk(
  "dashboard/upcomingVisaExp",
  async (filters: any) => {
    return dashboardService.getVisaExpiryPolicyApi(filters);
  },
);

export const getMapsData = createAsyncThunk(
  "dashboard/mapsData",
  async (filters: any) => {
    return dashboardService.getMapsDataApi(filters);
  },
);

export const getAuthDivisions = createAsyncThunk(
  "dashboard/getAuthDivisions",
  async () => {
    return dashboardService.getAuthDivisionsApi();
  },
);

export const servicesByPolicy = createAsyncThunk(
  "dashboard/servicePolicy",
  async (filters: any) => {
    return dashboardService.servicesByPolicy(filters);
  },
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateColors: (state, action) => {
      state.colors = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getYtdSpends.pending, (state) => {
      state.loaders.ytd = true;
    });
    builder.addCase(getYtdSpends.fulfilled, (state, action) => {
      state.loaders.ytd = false;
      state.ytd = action.payload?.totalYTDSpend;
    });
    builder.addCase(getYtdSpends.rejected, (state, action) => {
      state.loaders.ytd = false;
      state.error = action?.error?.message;
    });
    // ytd authorisations

    builder.addCase(getYtdAuthorisations.pending, (state) => {
      state.loaders.ytdAuths = true;
    });
    builder.addCase(getYtdAuthorisations.fulfilled, (state, action) => {
      state.loaders.ytdAuths = false;

      state.ytdAuths = action.payload?.totalYTDAuthorisations;
    });

    builder.addCase(getYtdActiveFiles.pending, (state) => {
      state.loaders.ytdActiveFiles = true;
    });
    builder.addCase(getYtdActiveFiles.fulfilled, (state, action) => {
      state.loaders.ytdActiveFiles = false;

      state.ytdActiveFiles = action.payload?.totalActiveFileCount;
    });

    builder.addCase(getYtdAuthorisations.rejected, (state, action) => {
      state.loaders.ytdAuths = false;
      state.error = action?.error?.message;
    });
    // authrisations  By Policy

    builder.addCase(getAuthsByPolicy.pending, (state) => {
      state.loaders.authByPolicy = true;
    });
    builder.addCase(getAuthsByPolicy.fulfilled, (state, action) => {
      state.loaders.authByPolicy = false;
      state.authByPolicy = action.payload?.authorisationsByPolicy?.map(
        (item: any, index: number) => {
          let policyColor = state?.colors?.find?.(
            (color: any) => color.name === item.program_name,
          )?.value;

          if (!policyColor) {
            policyColor = randomColorArray[index];
            const checkColor = () => {
              if (
                state?.colors?.find?.(
                  (color: any) => color.value === policyColor,
                )
              ) {
                policyColor =
                  randomColorArray[index] || exports.getRandomColor();
              }
            };
            checkColor();
            state.colors?.push?.({
              name: item.program_name,
              value: policyColor,
            });
          }

          return {
            name: item.program_name,
            value: item.fileNumberCount,
            color: policyColor,
          };
        },
      );
    });
    builder.addCase(getAuthsByPolicy.rejected, (state, action) => {
      state.loaders.authByPolicy = false;
      state.error = action?.error?.message;
    });
    // spends  By Policy

    builder.addCase(getSpendsByPolicy.pending, (state) => {
      state.loaders.spendsByPolicy = true;
    });
    builder.addCase(getSpendsByPolicy.fulfilled, (state, action) => {
      state.loaders.spendsByPolicy = false;
      state.spendsByPolicy = action.payload?.spendsByPolicy?.map(
        (item: any, index: number) => {
          let policyColor = state?.colors?.find?.(
            (color: any) => color.name === item.program_name,
          )?.value;

          if (!policyColor) {
            policyColor = randomColorArray[index];
            const checkColor = () => {
              if (
                state?.colors?.find?.(
                  (color: any) => color.value === policyColor,
                )
              ) {
                policyColor =
                  randomColorArray[index] || exports.getRandomColor();
              }
            };
            checkColor();
            state.colors.push({ name: item.program_name, value: policyColor });
          }

          return {
            name: item.program_name,
            value: item.totalAmount,
            color: policyColor,
          };
        },
      );
    });
    builder.addCase(getSpendsByPolicy.rejected, (state, action) => {
      state.loaders.spendsByPolicy = false;
      state.error = action?.error?.message;
    });

    // exceptions By Policy

    builder.addCase(getExceptionsByPolicy.pending, (state) => {
      state.loaders.exceptionsByPolicy = true;
    });
    builder.addCase(getExceptionsByPolicy.fulfilled, (state, action) => {
      state.loaders.exceptionsByPolicy = false;
      state.exceptionsByPolicy = action?.payload?.exceptionsByPolicy?.map(
        (item: any, index: number) => {
          let policyColor = state?.colors?.find?.(
            (color: any) => color.name === item.program_name,
          )?.value;

          if (!policyColor) {
            policyColor = randomColorArray[index];
            const checkColor = () => {
              if (
                state?.colors?.find?.(
                  (color: any) => color.value === policyColor,
                )
              ) {
                policyColor =
                  randomColorArray[index] || exports.getRandomColor();
              }
            };
            checkColor();
            state.colors.push({ name: item.program_name, value: policyColor });
          }

          return {
            name: item.program_name,
            value: item.totalAmount,
            color: policyColor,
          };
        },
      );
    });
    builder.addCase(getExceptionsByPolicy.rejected, (state, action) => {
      state.loaders.exceptionsByPolicy = false;
      state.error = action?.error?.message;
    });

    // visa expiry policy

    builder.addCase(getVisaExpiryPolicy.pending, (state) => {
      state.loaders.upcomingVisaExp = true;
    });
    builder.addCase(getVisaExpiryPolicy.fulfilled, (state, action) => {
      state.loaders.upcomingVisaExp = false;
      state.upcomingVisaExp = action?.payload?.upcomingVisaExpiry;
    });
    builder.addCase(getVisaExpiryPolicy.rejected, (state, action) => {
      state.loaders.upcomingVisaExp = false;
      state.error = action?.error?.message;
    });

    // maps data
    builder.addCase(getMapsData.pending, (state) => {
      state.loaders.mapsData = true;
    });
    builder.addCase(getMapsData.fulfilled, (state, action) => {
      state.loaders.mapsData = false;
      state.mapsData = action.payload?.mapsData;
    });
    builder.addCase(getMapsData.rejected, (state, action) => {
      state.loaders.mapsData = false;
      state.error = action?.error?.message;
    });

    // service policy data
    builder.addCase(servicesByPolicy.pending, (state) => {
      state.loaders.servicesByPolicy = true;
    });
    builder.addCase(servicesByPolicy.fulfilled, (state, action: any) => {
      state.loaders.servicesByPolicy = false;
      state.servicesByPolicy = action?.payload?.data?.getServiceByPolicy;
    });
    builder.addCase(servicesByPolicy.rejected, (state, action) => {
      state.loaders.servicesByPolicy = false;
      state.error = action?.error?.message;
    });

    // auth test
    builder.addCase(getAuthDivisions.pending, (state) => {
      state.loaders.divisions = true;
    });
    builder.addCase(getAuthDivisions.fulfilled, (state, action) => {
      state.loaders.divisions = false;
      state.divisions = action?.payload?.authorizedDivisions;
    });
    builder.addCase(getAuthDivisions.rejected, (state, action) => {
      state.loaders.divisions = false;
      state.error = action?.error?.message;
    });
  },
});

export default dashboardSlice.reducer;
export const { resetState, updateColors } = dashboardSlice.actions;
