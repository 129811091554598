import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientMemberService from "../../services/ClientMember/ClientMember";

export const initialState: any = {
  listing: {
    error: null,
    loading: true,
    data: {},
    vars: {
      page: 1,
      pageSize: 10,
      searchKey: "",
    },
  },
  view: {
    error: null,
    loading: true,
    data: {},
  },
  roles: {
    error: null,
    loading: true,
    data: {},
  },
  form: {
    name: "",
    topLevel: false,
    notes: "",
  },
  members: [],
};

// ***********************************
export const getListing = createAsyncThunk(
  "ACTION_GET_CLIENT_MEMBER_LIST",
  async (data: any) => {
    return clientMemberService.getListing(data);
  },
);

const getListingFullfield = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.data = action.payload?.data;
  state.listing.error = null;
};
const getListingPending = (state: any, action: any) => {
  state.listing.loading = true;
  state.listing.error = action?.error?.message;
};
const getListingRejected = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.error = action?.error?.message;
};

// ***********************************
export const getView = createAsyncThunk(
  "ACTION_GET_CLIENT_MEMBER_VIEW",
  async (id: string) => {
    return clientMemberService.getView(id);
  },
);

const getViewFullfield = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = action.payload?.data;
  state.view.error = null;
};
const getViewPending = (state: any, action: any) => {
  state.view.loading = true;
  state.view.error = action?.error?.message;
};
const getViewRejected = (state: any, action: any) => {
  state.view.loading = false;
  state.view.error = action?.error?.message;
};

// ***********************************
export const getRoles = createAsyncThunk(
  "ACTION_GET_CLIENT_MEMBER_ROLES",
  async (data: any) => {
    return clientMemberService.getRoles(data);
  },
);

const getRolesFullfield = (state: any, action: any) => {
  state.roles.loading = false;
  state.roles.data = action.payload?.data;
  state.roles.error = null;
};
const getRolesPending = (state: any, action: any) => {
  state.roles.loading = true;
  state.roles.error = action?.error?.message;
};
const getRolesRejected = (state: any, action: any) => {
  state.roles.loading = false;
  state.roles.error = action?.error?.message;
};

const clientMemberSlice = createSlice({
  name: "ClientMember",
  initialState,
  reducers: {
    resetState: () => initialState,
    udpateForm: (state, action) => {
      state.form = action.payload;
    },
    updateMembers: (state, action) => {
      state.members = action.payload;
    },
    resetEdit: (state) => {
      state.form = initialState.form;
      state.members = initialState.members;
    },
    updateVars: (state, action) => {
      state.listing.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    // ***********************************
    builder.addCase(getListing.fulfilled, getListingFullfield);
    builder.addCase(getListing.pending, getListingPending);
    builder.addCase(getListing.rejected, getListingRejected);

    // ***********************************
    builder.addCase(getView.fulfilled, getViewFullfield);
    builder.addCase(getView.pending, getViewPending);
    builder.addCase(getView.rejected, getViewRejected);

    // ***********************************
    builder.addCase(getRoles.fulfilled, getRolesFullfield);
    builder.addCase(getRoles.pending, getRolesPending);
    builder.addCase(getRoles.rejected, getRolesRejected);
  },
});

export default clientMemberSlice.reducer;
export const { resetState, updateVars, udpateForm, updateMembers, resetEdit } =
  clientMemberSlice.actions;
