import { notification } from "antd";
import { ReactNode } from "react";

const DURATION = 3;

class Toast {
  constructor() {
    notification.config({
      maxCount: 2,
      placement: "bottomRight",
    });
  }

  success = (
    description: string,
    message?: string,
    closeButton?: ReactNode,
  ) => {
    notification.success({
      message: message ?? "Success",
      description,
      duration: DURATION,
      btn: closeButton,
    });
  };

  info = (description: string, message?: string, closeButton?: ReactNode) => {
    notification.info({
      message: message ?? "Info",
      description,
      duration: DURATION,
      btn: closeButton,
    });
  };

  error = (description: string, message?: string, closeButton?: ReactNode) => {
    notification.error({
      message: message ?? "Error",
      description,
      duration: DURATION,
      btn: closeButton,
    });
  };

  warning = (
    description: string,
    message?: string,
    closeButton?: ReactNode,
  ) => {
    notification.warning({
      message: message ?? "Warning",
      description,
      duration: DURATION,
      btn: closeButton,
    });
  };
}

export const Notify = new Toast();
