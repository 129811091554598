import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: any = [""];

//create a slice for Client
const siderbarMenu = createSlice({
  name: "siderbarMenu",
  initialState,
  reducers: {
    setMenu: (state: any, action: any) => {
      return [...action.payload];
    },
  },
});

export default siderbarMenu.reducer;
const setMenuAction: any = siderbarMenu.actions.setMenu;
export const setMenu = setMenuAction;
