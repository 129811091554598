import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financialOverviewService from "../../services/FinancialOverview/FinancialOverview";

export const initialState: any = {
  view: {
    error: null,
    loading: false,
    data: {},
  },
};

// ACTION_GET_FILES
export const getFinancialView = createAsyncThunk(
  "ACTION_GET_FINANCIAL_VIEW",
  async (id: string) => {
    return financialOverviewService.getView(id);
  },
);

const getViewFullfield = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = action.payload?.data;
  state.view.error = null;
};

const getViewPending = (state: any, action: any) => {
  state.view.loading = true;
  state.view.error = action.error ? action?.error?.message : null;
};

const getViewRejected = (state: any, action: any) => {
  state.view.loading = false;
  state.view.error = action.error ? action?.error?.message : "Error";
};

const financialOverviewSlice = createSlice({
  name: "financialOverview",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    // ACTION_GET_FILES
    builder
      .addCase(getFinancialView.fulfilled, getViewFullfield)
      .addCase(getFinancialView.pending, getViewPending)
      .addCase(getFinancialView.rejected, getViewRejected);
  },
});

export default financialOverviewSlice.reducer;
export const { resetState } = financialOverviewSlice.actions;
