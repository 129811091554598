import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const SuspenseLoading = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "20px",
    }}
  >
    <Spin indicator={antIcon} /> Loading...
  </div>
);

export default SuspenseLoading;
