import axios from "..";
import {
  CREATE_AUTORIZATION,
  DELETE_AUTORIZATION,
  GET_EXPENSE_AUTHORIZATION_LIST,
  GET_MILO_EXPENSE_LIST,
  GET_RESOLVED_EXPENSE_LIST,
  UPDATE_AUTORIZATION,
  MATCH_AUTORIZATION,
  UNMATCH_AUTORIZATION,
} from "../../resources/constants";

class expenseAuthorizationService {
  public static async getExpenseAuthorization(id: any) {
    return await axios.get(
      GET_EXPENSE_AUTHORIZATION_LIST + "/?transfereeId=" + id,
    );
  }

  public static async getMiloExpense(id: any) {
    return await axios.get(GET_MILO_EXPENSE_LIST + "/?transfereeId=" + id);
  }

  public static async getResolvedExpense(id: any) {
    return await axios.get(GET_RESOLVED_EXPENSE_LIST + "/?transfereeId=" + id);
  }

  public static async createAuthorization(data: any) {
    return await axios.post(CREATE_AUTORIZATION, data);
  }

  public static async updateAuthorization(data: any) {
    return await axios.put(UPDATE_AUTORIZATION + "/" + data.id, data);
  }

  public static async deleteAuthorization(id: any) {
    return await axios.del(DELETE_AUTORIZATION + "/" + id);
  }

  public static async matchAuthorization(id: any, data: any) {
    return await axios.post(MATCH_AUTORIZATION + "/?transfereeId=" + id, data);
  }

  public static async unmatchAuthorization(id: any, data: any) {
    return await axios.post(
      UNMATCH_AUTORIZATION + "/?transfereeId=" + id,
      data,
    );
  }
}

export default expenseAuthorizationService;
