import { createSlice } from "@reduxjs/toolkit";

export interface showSliderType {
  showSlider: boolean;
  navigateTo: string;
}

//data
const initialState: showSliderType = {
  showSlider: true,
  navigateTo: "-1",
};

const DisplayConfigsSlice = createSlice({
  name: "showSlider",
  initialState,
  reducers: {
    updateSliderState: (state, action) => {
      state.showSlider = action.payload.showSlider;
      state.navigateTo = action.payload.navigateTo;
    },
  },
  extraReducers: function (builder): void {},
});

export const { updateSliderState } = DisplayConfigsSlice.actions;

export default DisplayConfigsSlice.reducer;
