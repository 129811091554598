import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalLoaderState {
  isLoading: boolean;
  showProgress: boolean;
  showProgressText: boolean;
}

const initialState: GlobalLoaderState = {
  isLoading: false,
  showProgress: false,
  showProgressText: false,
};

const globalLoaderSlice = createSlice({
  name: "globalLoader",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setProgress: (state, action: PayloadAction<boolean>) => {
      state.showProgress = action.payload;
    },
    setProgressText: (state, action: PayloadAction<boolean>) => {
      state.showProgressText = action.payload;
    },
  },
});

export const { setLoading, setProgress, setProgressText } =
  globalLoaderSlice.actions;

export default globalLoaderSlice.reducer;
