import React, { useEffect } from "react";
import { Col, Row } from "antd";
import ErrorComp from "../../components/Error/Error";
import "../../styles/pages/PageNotFound/PageNotFound.scss";
import { reloLogo } from "../../resources/constants";
import ImageComp from "../../components/CustsomImage/ImageComp";

import { setMenu } from "../../store/SidebarMenu/slice";
import { useDispatch, useSelector } from "react-redux";

const PageNotFound = (props: any) => {
  const dispatch = useDispatch();

  return (
    <Row className="ErrorPage">
      <Col>
        {" "}
        <ImageComp className="pageNotFoundLogo" src={reloLogo}></ImageComp>
      </Col>
      <Col className="pageNotFoundContent">
        <ErrorComp code={props.code}></ErrorComp>;
      </Col>
    </Row>
  );
};

export default PageNotFound;
