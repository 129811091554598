export const filterPolicy = [
  { name: "Tier 1", checked: false },
  { name: "Tier 2", checked: false },
  { name: "Tier 3", checked: false },
  { name: "Tier 4", checked: false },
];

export let filterStatus = [
  { name: "Hold", checked: false },
  { name: "Pending", checked: false },
  { name: "Initiated", checked: false },
  { name: "Active", checked: false },
  { name: "Move Complete", checked: false },
  { name: "Initiation Pending", checked: false },
];

export const dashboardFilters = {
  TOTAL_YTD_SPEND: "total_ytd_spend",
  TOTAL_YTD_AUTHORISATIONS: "total_ytd_authorisations",
  TOTAL_YTD_ACTIVE_FILES: "total_ytd_active_files",
  UPCOMING_VIAS_EXPIRY: "upcoming_vias_expiry",
  AUTHORISATIONS_BY_POLICY: "authorisations_by_policy",
  SPENDS_BY_POLICY: "spends_by_policy",
  EXCEPTIONS_BY_POLICY: "exceptions_by_policy",
  MAPS_DATA: "maps_data",
  SERVICE_BY_POLICY: "service_by_policy",
  DIVISION: "division",
  REPORTS: "reports",
  vendorTrackingMoveHelp: "vendorTrackingMoveHelp",
};
