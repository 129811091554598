import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import policyService from "../../services/Policy/Policy";

export const initialState: any = {
  listing: {
    error: null,
    loading: true,
    data: {},
    vars: {
      page: 1,
      search: "",
    },
  },
  view: {
    error: null,
    loading: true,
    data: {},
  },
  config: {
    error: null,
    loading: true,
    data: {},
  },
  remList: {
    error: null,
    loading: true,
    data: {},
  },
  categoryConfig: {
    error: null,
    loading: true,
    data: {},
  },
  limitGroup: {
    error: null,
    loading: true,
    data: {},
  },
  logs: {
    data: {},
    error: null,
    loading: false,
  },
};

// ***********************************
export const getListing = createAsyncThunk(
  "ACTION_POLICY_GET_LISTING",
  async (data: any) => {
    return policyService.getListing(data);
  },
);

const getListingFullfield = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.data = action.payload?.data;
  state.listing.error = null;
};
const getListingPending = (state: any, action: any) => {
  state.listing.loading = true;
  state.listing.data = {};
  state.listing.error = action?.error?.message;
};
const getListingRejected = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.data = {};
  state.listing.error = action?.error?.message;
};

// ***********************************
export const getPolicyLogs = createAsyncThunk(
  "ACTION_POLICY_GET_LOGS",
  async (data: any) => {
    return policyService.getPolicyLogs(data);
  },
);

const getPolicyLogsFullfield = (state: any, action: any) => {
  state.logs.loading = false;
  state.logs.data = action.payload?.data;
  state.logs.error = null;
};
const getPolicyLogsPending = (state: any, action: any) => {
  state.logs.loading = true;
  state.logs.data = {};
  state.logs.error = action?.error?.message;
};
const getPolicyLogsRejected = (state: any, action: any) => {
  state.logs.loading = false;
  state.logs.data = {};
  state.logs.error = action?.error?.message;
};

// ***********************************
export const getView = createAsyncThunk(
  "ACTION_POLICY_GET_VIEW",
  async (data: any) => {
    return policyService.getView(data);
  },
);

const getViewFullfield = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = action.payload?.data;
  state.view.error = null;
};
const getViewPending = (state: any, action: any) => {
  state.view.loading = true;
  state.view.data = {};
  state.view.error = action?.error?.message;
};
const getViewRejected = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = {};
  state.view.error = action?.error?.message;
};

// ***********************************
export const getConfig = createAsyncThunk(
  "ACTION_POLICY_GET_CONFIG",
  async (id: string) => {
    return policyService.getConfig(id);
  },
);

const getConfigFullfield = (state: any, action: any) => {
  state.config.loading = false;
  state.config.data = action.payload?.data;
  state.config.error = null;
};
const getConfigPending = (state: any, action: any) => {
  state.config.loading = true;
  state.config.data = {};
  state.config.error = action?.error?.message;
};
const getConfigRejected = (state: any, action: any) => {
  state.config.loading = false;
  state.config.data = {};
  state.config.error = action?.error?.message;
};

// ***********************************
export const getREMList = createAsyncThunk(
  "ACTION_POLICY_GET_REM_LIST",
  async (id: string) => {
    return policyService.getREMList(id);
  },
);

const getREMListFullfield = (state: any, action: any) => {
  state.remList.loading = false;
  state.remList.data = action.payload?.data;
  state.remList.error = null;
};
const getREMListPending = (state: any, action: any) => {
  state.remList.loading = true;
  state.remList.data = {};
  state.remList.error = action?.error?.message;
};
const getREMListRejected = (state: any, action: any) => {
  state.remList.loading = false;
  state.remList.data = {};
  state.remList.error = action?.error?.message;
};

// ***********************************
export const getCategoryConfig = createAsyncThunk(
  "ACTION_POLICY_GET_LIMIT_GROUP",
  async (id: string) => {
    return policyService.getCategoryConfig(id);
  },
);

const getCategoryConfigFullfield = (state: any, action: any) => {
  state.categoryConfig.loading = false;
  state.categoryConfig.data = action.payload?.data;
  state.categoryConfig.error = null;
};
const getCategoryConfigPending = (state: any, action: any) => {
  state.categoryConfig.loading = true;
  state.categoryConfig.data = {};
  state.categoryConfig.error = action?.error?.message;
};
const getCategoryConfigRejected = (state: any, action: any) => {
  state.categoryConfig.loading = false;
  state.categoryConfig.data = {};
  state.categoryConfig.error = action?.error?.message;
};

// ***********************************
export const getLimitGroup = createAsyncThunk(
  "ACTION_POLICY_GET_CATEGORY_CONFIG",
  async (id: string) => {
    return policyService.getLimitGroup(id);
  },
);

const getLimitGroupFullfield = (state: any, action: any) => {
  state.limitGroup.loading = false;
  state.limitGroup.data = action.payload?.data;
  state.limitGroup.error = null;
};
const getLimitGroupPending = (state: any, action: any) => {
  state.limitGroup.loading = true;
  state.limitGroup.data = {};
  state.limitGroup.error = action?.error?.message;
};
const getLimitGroupRejected = (state: any, action: any) => {
  state.limitGroup.loading = false;
  state.limitGroup.data = {};
  state.limitGroup.error = action?.error?.message;
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateVars: (state, action) => {
      state.listing.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    // ***********************************
    builder.addCase(getListing.fulfilled, getListingFullfield);
    builder.addCase(getListing.pending, getListingPending);
    builder.addCase(getListing.rejected, getListingRejected);

    // ***********************************
    builder.addCase(getView.fulfilled, getViewFullfield);
    builder.addCase(getView.pending, getViewPending);
    builder.addCase(getView.rejected, getViewRejected);

    // ***********************************
    builder.addCase(getConfig.fulfilled, getConfigFullfield);
    builder.addCase(getConfig.pending, getConfigPending);
    builder.addCase(getConfig.rejected, getConfigRejected);

    // ***********************************
    builder.addCase(getREMList.fulfilled, getREMListFullfield);
    builder.addCase(getREMList.pending, getREMListPending);
    builder.addCase(getREMList.rejected, getREMListRejected);

    // ***********************************
    builder.addCase(getCategoryConfig.fulfilled, getCategoryConfigFullfield);
    builder.addCase(getCategoryConfig.pending, getCategoryConfigPending);
    builder.addCase(getCategoryConfig.rejected, getCategoryConfigRejected);

    // ***********************************
    builder.addCase(getLimitGroup.fulfilled, getLimitGroupFullfield);
    builder.addCase(getLimitGroup.pending, getLimitGroupPending);
    builder.addCase(getLimitGroup.rejected, getLimitGroupRejected);

    // ***********************************
    builder.addCase(getPolicyLogs.fulfilled, getPolicyLogsFullfield);
    builder.addCase(getPolicyLogs.pending, getPolicyLogsPending);
    builder.addCase(getPolicyLogs.rejected, getPolicyLogsRejected);
  },
});

export default policySlice.reducer;
export const { resetState, updateVars } = policySlice.actions;
