import axios from "..";
import {
  GET_COUNTRY_URL,
  GET_STATE_URL,
  GET_CITY_URL,
} from "../../resources/constants";

let countriesCache: any = null;
class countryStateCityService {
  public static async getCountry() {
    if (countriesCache) {
      return countriesCache;
    }
    const res = await axios.get(`${GET_COUNTRY_URL}`);
    countriesCache = res;
    return res;
  }

  public static async getState(countryId: string) {
    return await axios.get(`${GET_STATE_URL}/?countryId=${countryId}`);
  }

  public static async getCity(countryId: string, stateId: string) {
    return await axios.get(
      `${GET_CITY_URL}/?countryId=${countryId}&stateId=${stateId}`,
    );
  }
}

export default countryStateCityService;
