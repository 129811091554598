import {
  GET_BUDGET,
  CREATE_BUDGET,
  GET_EXPENSE_LIST,
  CREATE_SG_EXPENSE,
  DELETE_SG_EXPENSE,
} from "../../resources/constants";
import axios from "..";

export default class SelfGuidedService {
  public static async getBudget(id: any) {
    return await axios.get(GET_BUDGET + "/" + id);
  }

  public static async setBudget(data: any) {
    return await axios.put(CREATE_BUDGET, data);
  }

  public static async getSGList(id: any) {
    return await axios.get(GET_EXPENSE_LIST + "/" + id);
  }

  public static async createExpense(data: any) {
    return await axios.put(CREATE_SG_EXPENSE, data);
  }

  public static async deleteExpense(data: any) {
    return await axios.del(DELETE_SG_EXPENSE, data);
  }
}
