export const isStringified = (input: string = "") => {
  try {
    return JSON.parse(input);
  } catch {
    return input;
  }
};

export const getUserRole = (userRoles: any) => {
  const roleMapping: { [key: string]: string } = {
    ROLE_ADMIN: "admin",
    ROLE_CLIENT: "client",
    ROLE_TRANSFEREE: "transferee",
    ROLE_SUPPLIER: "supplier",
    ROLE_FINANCE: "admin",
    ROLE_CONSULTANT: "admin",
    ROLE_ACCOUNT_MANAGER: "admin",
    // roles not in use
    ROLE_INTERNAL: "admin",
    ROLE_CARRIER_ADMIN: "carrierAdmin",
    ROLE_COORDINATOR: "coordinator",
    ROLE_ASSOCIATE: "associate",
    ROLE_TEMPLIVINGCOORDINATOR: "templivingCoordinator",
    ROLE_SALES: "sales",
    ROLE_OWNER: "owner",
    ROLE_DEVELOPER: "developer",
    ROLE_EXTERNAL: "external",
  };

  const matchingRole = userRoles?.find((role: any) =>
    roleMapping.hasOwnProperty(role.authority),
  );

  return matchingRole ? roleMapping[matchingRole.authority] : "";
};
