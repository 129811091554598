import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import loginServices from "../../services/Login/login";
import {
  ACTION_FORGOT_PASSWORD,
  ACTION_FORGOT_USERNAME,
  ACTION_GET_USER_INFO,
  ACTION_LOGIN,
  ACTION_SIGNUP,
} from "../../Actions/actions";
import { getUserRole } from "../../utils/helper";
import { token } from "../../services";
import { setCookie } from "../../utils/cookies";
import { logout } from "../../utils/logout";

export interface User {
  id?: number;
  firstName?: string;
  name?: string;
  lastName?: string;
  email?: string;
  email_verified_at?: string;
  created_at?: string;
  updated_at?: string;
  role?: string;
  permissions?: string;
  avatar?: string;
  address?: string;
  profile?: string;
  phone?: string;
  logo?: string;
  client?: string;
  clientLogo?: string;
  supplierId;
  clientId?: string;
  clientName?: string;
  divisionId?: string;
  profilePicS3key?: string;
  policyType?: string;
  tlrId?: string;
  cmRoles?: [];
  userRoles?: [];
  bankInfoUpdated?: boolean;
  allowExpenses?: boolean;
  showBankInfo?: boolean;
}

export type loginPageState = {
  user?: User | null;
  loading: boolean;
  error?: any;
  user_role?: string;
  success?: boolean;
  loggedIn?: boolean;
  signedUp?: boolean;
  forgotPassword?: boolean;
  forgotUsername?: boolean;
};

interface payload {
  username: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
}

const initialState: any = {
  user: null,
  loading: false,
  user_role: "",
  error: null,
  success: false,
  loggedIn: true,
  signedUp: false,
  forgotPassword: false,
  forgotUsername: false,
};

export const getLoginDetails = createAsyncThunk(
  ACTION_LOGIN,
  async ({ username, password }: any) => {
    return loginServices.getLoginDetails(username, password);
  },
);
export const signUpUser = createAsyncThunk(
  ACTION_SIGNUP,
  async (payload: any) => {
    return loginServices.signUp(payload);
  },
);

export const getUserInfo = createAsyncThunk(ACTION_GET_USER_INFO, async () => {
  return loginServices.getUserDetails();
});

export const forgotPassword = createAsyncThunk(
  ACTION_FORGOT_PASSWORD,
  async (username: any) => {
    return loginServices.forgotPassword(username);
  },
);

export const forgotUsername = createAsyncThunk(
  ACTION_FORGOT_USERNAME,
  async (email: any) => {
    return loginServices.forgotUserName(email);
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearAllStateValue: (state) => {
      state.signedUp = false;
      state.forgotPassword = false;
      state.forgotUsername = false;
      state.error = "";
    },
    clearError: (state) => {
      state.error = "";
    },
    clearRole: (state) => {
      state.user_role = "";
    },
  },
  extraReducers(builder): void {
    builder.addCase(getLoginDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoginDetails.fulfilled, (state, action) => {
      const { data, refreshToken, accessToken } = action.payload;
      state.loading = false;

      if (data?.firstTimeLogin) {
        state.user_role = "firstTimeLogin";
      } else {
        let role = "";
        role = getUserRole(data.userRoles);
        state.user_role = role;
        state.user = {
          ...data,
          role: role,
          bankInfoUpdated: data?.bankInfoUpdated || false,
          allowExpenses: data?.allowExpenses || false,
          showBankInfo: data?.showBankInfo || false,
          userRoles: data?.userRoles,
          clientLogo: data?.user?.clientLogo,
          cmRoles: data?.cmRoles || [],
        };
        setCookie("refreshToken", refreshToken, 1);
        window.localStorage.setItem("lastTime", new Date().getTime() + "");
        if (accessToken) {
          token.access = accessToken;
        }

        const url = window.localStorage.getItem("redirect");
        window.localStorage.removeItem("redirect");
        if (url && url !== "/dashboard" && url !== "/") {
          window.location.href = url;
        }
      }
    });

    builder.addCase(getLoginDetails.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error;
    });

    //forgot password
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword = true;
      state.error = "";
    });

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error;
    });

    //forgot username
    builder.addCase(forgotUsername.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotUsername.fulfilled, (state, action) => {
      state.error = "";
      state.forgotUsername = true;
      state.loading = false;
    });
    builder.addCase(forgotUsername.rejected, (state, action) => {
      state.error = action.error;
      state.forgotUsername = false;
      state.loading = false;
    });

    //signup
    builder.addCase(signUpUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      const { data, refreshToken, accessToken } = action.payload;
      state.loading = false;
      let role = "";
      role = getUserRole(data.userRoles);
      state.user_role = role;
      state.user = {
        ...data,
        role: role,
        bankInfoUpdated: action?.payload?.bankInfoUpdated || false,
        allowExpenses: action?.payload?.allowExpenses || false,
        showBankInfo: action?.payload?.showBankInfo || false,
        userRoles: action?.payload?.userRoles,
        clientLogo: action?.payload?.user?.clientLogo,
        cmRoles: action?.payload?.cmRoles || [],
      };
      setCookie("refreshToken", refreshToken, 1);
      window.localStorage.setItem("lastTime", new Date().getTime() + "");
      token.access = accessToken;
      localStorage.removeItem("redirect");
    });

    builder.addCase(signUpUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error;
    });

    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      let role = getUserRole(action?.payload?.userRoles);
      state.user_role = role;
      state.user = {
        ...action.payload,
        ...action.payload.user,
        userRoles: action?.payload?.userRoles,
        clientLogo: action?.payload?.user?.clientLogo,
        role: role,
        bankInfoUpdated: action?.payload?.bankInfoUpdated || false,
        allowExpenses: action?.payload?.allowExpenses || false,
        showBankInfo: action?.payload?.showBankInfo || false,
        cmRoles: action?.payload?.cmRoles || [],
      };
      localStorage.removeItem("redirect");
    });

    builder.addCase(getUserInfo.rejected, (state, action) => {
      logout();
    });
  },
});

export const { clearAllStateValue, clearError, clearRole } = userSlice.actions;

export default userSlice.reducer;
