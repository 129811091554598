import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import expenseSubmissionService from "../../services/ExpenseSubmission/ExpenseSubmission";

export const initialState: any = {
  listing: {
    error: null,
    loading: true,
    data: {},
    vars: {
      page: 1,
      pageSize: 10,
      filtersOpen: false,
      categoryId: "",
      clientId: "",
      transfereeId: "",
      consultantId: "",
      financeUserId: "",
      folderId: "",
      status: "",
      searchKey: "",
    },
  },
  filters: {
    error: null,
    loading: true,
    data: {},
  },
  view: {
    error: null,
    loading: false,
    data: {},
  },
  folders: {
    error: null,
    loading: false,
    data: {},
  },
  category: {
    error: null,
    loading: true,
    data: {},
  },
  type: {
    error: null,
    loading: true,
    data: {},
  },
  typeosr: {
    error: null,
    loading: true,
    data: {},
  },
  benefitLimits: {
    error: null,
    loading: true,
    data: {},
  },
  history: {
    error: null,
    loading: true,
    data: {},
  },
};

// ***********************************
export const getExpenseHistory = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_HISTORY",
  async (data: any) => {
    return expenseSubmissionService.getExpenseHistory(data.id, data.params);
  },
);

const getExpenseHistoryFullfield = (state: any, action: any) => {
  state.history.loading = false;
  state.history.data = action.payload?.data;
  state.history.error = null;
};
const getExpenseHistoryPending = (state: any, action: any) => {
  state.history.loading = true;
  state.history.data = {};
  state.history.error = action?.error?.message;
};
const getExpenseHistoryRejected = (state: any, action: any) => {
  state.history.loading = false;
  state.history.data = {};
  state.history.error = action?.error?.message;
};

// ***********************************
export const getListing = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_LIST",
  async (data: any) => {
    return expenseSubmissionService.getListing(data.filters, data.params);
  },
);

const getListingFullfield = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.data = action.payload?.data;
  state.listing.error = null;
};
const getListingPending = (state: any, action: any) => {
  state.listing.loading = true;
  state.listing.error = action?.error?.message;
};
const getListingRejected = (state: any, action: any) => {
  state.listing.loading = false;
  state.listing.error = action?.error?.message;
};

// ***********************************
export const getBenefitLimits = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_BENIFIT_LIMITS",
  async (data: any) => {
    return expenseSubmissionService.getBenefitLimits(data);
  },
);

const getBenefitLimitsFullfield = (state: any, action: any) => {
  state.benefitLimits.loading = false;
  state.benefitLimits.data = action.payload?.data;
  state.benefitLimits.error = null;
};
const getBenefitLimitsPending = (state: any, action: any) => {
  state.benefitLimits.loading = true;
  state.benefitLimits.error = action?.error?.message;
  state.benefitLimits.data = {};
};
const getBenefitLimitsRejected = (state: any, action: any) => {
  state.benefitLimits.loading = false;
  state.benefitLimits.error = action?.error?.message;
  state.benefitLimits.data = {};
};

// ***********************************
export const getFilters = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_FILTERS",
  async () => {
    return expenseSubmissionService.getFilters();
  },
);

const getFiltersFullfield = (state: any, action: any) => {
  state.filters.loading = false;
  state.filters.data = action.payload?.data;
  state.filters.error = null;
};
const getFiltersPending = (state: any, action: any) => {
  state.filters.loading = true;
  state.filters.error = action?.error?.message;
};
const getFiltersRejected = (state: any, action: any) => {
  state.filters.loading = false;
  state.filters.error = action?.error?.message;
};

// ***********************************
export const getView = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_BY_ID",
  async (id: string) => {
    return expenseSubmissionService.getView(id);
  },
);

const getViewFullfield = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = action.payload?.data;
  state.view.error = null;
};
const getViewPending = (state: any, action: any) => {
  state.view.loading = true;
  state.view.error = action?.error?.message;
};
const getViewRejected = (state: any, action: any) => {
  state.view.loading = false;
  state.view.error = action?.error?.message;
};

// ***********************************
export const getFolders = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_FOLDER_LIST",
  async (id: string) => {
    return expenseSubmissionService.getFolders(id);
  },
);

const getFoldersFullfield = (state: any, action: any) => {
  state.folders.loading = false;
  state.folders.data = action.payload?.data;
  state.folders.error = null;
};
const getFoldersPending = (state: any, action: any) => {
  state.folders.loading = true;
  state.folders.error = action?.error?.message;
};
const getFoldersRejected = (state: any, action: any) => {
  state.folders.loading = false;
  state.folders.error = action?.error?.message;
};

// ***********************************

export const getType = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_TYPE",
  async (data: any) => {
    return expenseSubmissionService.getType(data);
  },
);

const getTypeFullfield = (state: any, action: any) => {
  state.type.loading = false;
  state.type.data = action.payload?.data;
  state.type.error = null;
};
const getTypePending = (state: any, action: any) => {
  state.type.loading = true;
  state.type.error = action?.error?.message;
};
const getTypeRejected = (state: any, action: any) => {
  state.type.loading = false;
  state.type.error = action?.error?.message;
};

// ***********************************

export const getTypeosr = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_TYPE_OSR",
  async (data: any) => {
    return expenseSubmissionService.getType(data);
  },
);

const getTypeosrFullfield = (state: any, action: any) => {
  state.typeosr.loading = false;
  state.typeosr.data = action.payload?.data;
  state.typeosr.error = null;
};
const getTypeosrPending = (state: any, action: any) => {
  state.typeosr.loading = true;
  state.typeosr.error = action?.error?.message;
};
const getTypeosrRejected = (state: any, action: any) => {
  state.typeosr.loading = false;
  state.typeosr.error = action?.error?.message;
};

// ***********************************

export const getCategory = createAsyncThunk(
  "ACTION_GET_EXPENSE_SUBMISSION_CATEGORY_LIST",
  async (data: any) => {
    return expenseSubmissionService.getCategory(data);
  },
);

const getCategoryFullfield = (state: any, action: any) => {
  state.category.loading = false;
  state.category.data = action.payload?.data;
  state.category.error = null;
};
const getCategoryPending = (state: any, action: any) => {
  state.category.loading = true;
  state.category.error = action?.error?.message;
};
const getCategoryRejected = (state: any, action: any) => {
  state.category.loading = false;
  state.category.error = action?.error?.message;
};

// ***********************************

const expenseSubmissionSlice = createSlice({
  name: "ExpenseSubmission",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetView(state) {
      state.view.data = {};
    },
    updateVars(state, action) {
      state.listing.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    // ***********************************
    builder.addCase(getListing.fulfilled, getListingFullfield);
    builder.addCase(getListing.pending, getListingPending);
    builder.addCase(getListing.rejected, getListingRejected);

    // ***********************************
    builder.addCase(getFilters.fulfilled, getFiltersFullfield);
    builder.addCase(getFilters.pending, getFiltersPending);
    builder.addCase(getFilters.rejected, getFiltersRejected);

    // ***********************************
    builder.addCase(getView.fulfilled, getViewFullfield);
    builder.addCase(getView.pending, getViewPending);
    builder.addCase(getView.rejected, getViewRejected);

    // ***********************************
    builder.addCase(getFolders.fulfilled, getFoldersFullfield);
    builder.addCase(getFolders.pending, getFoldersPending);
    builder.addCase(getFolders.rejected, getFoldersRejected);

    // ***********************************
    builder.addCase(getCategory.fulfilled, getCategoryFullfield);
    builder.addCase(getCategory.pending, getCategoryPending);
    builder.addCase(getCategory.rejected, getCategoryRejected);

    // ***********************************
    builder.addCase(getType.fulfilled, getTypeFullfield);
    builder.addCase(getType.pending, getTypePending);
    builder.addCase(getType.rejected, getTypeRejected);

    // ***********************************
    builder.addCase(getTypeosr.fulfilled, getTypeosrFullfield);
    builder.addCase(getTypeosr.pending, getTypeosrPending);
    builder.addCase(getTypeosr.rejected, getTypeosrRejected);

    // ***********************************
    builder.addCase(getBenefitLimits.fulfilled, getBenefitLimitsFullfield);
    builder.addCase(getBenefitLimits.pending, getBenefitLimitsPending);
    builder.addCase(getBenefitLimits.rejected, getBenefitLimitsRejected);

    // ***********************************
    builder.addCase(getExpenseHistory.fulfilled, getExpenseHistoryFullfield);
    builder.addCase(getExpenseHistory.pending, getExpenseHistoryPending);
    builder.addCase(getExpenseHistory.rejected, getExpenseHistoryRejected);
  },
});

export default expenseSubmissionSlice.reducer;
export const { resetState, updateVars, resetView } =
  expenseSubmissionSlice.actions;
