import axios from "..";
import {
  GET_BANKING_INFORMATION_LIST,
  GET_BANKING_INFORMATION,
  CREATE_BANKING_ACCOUNT,
  UPDATE_BANKING_ACCOUNT,
  DELETE_BANKING_ACCOUNT,
  GET_BANK_INFO_UPDATES_LIST,
  GET_BANK_INFO,
  BANK_INFO_LIST,
  SET_DISMISS_FOR_IDS,
  EXPORT_BANK_INFO,
} from "../../resources/constants";

class bankingInformationService {
  public static async getBankingAccounts(id: string) {
    return await axios.get(`${GET_BANKING_INFORMATION_LIST}/${id}`);
  }

  public static async getBankingView(id: string) {
    return await axios.get(`${GET_BANKING_INFORMATION}/${id}`);
  }

  public static async createBankAccount(data: any) {
    return await axios.post(CREATE_BANKING_ACCOUNT, data);
  }

  public static async getBankInfoUpdates() {
    return await axios.get(GET_BANK_INFO_UPDATES_LIST);
  }

  public static async getBankInfo(id: string) {
    return await axios.get(GET_BANK_INFO + "/" + id);
  }

  public static async getBankInfoList(transfereeId: string) {
    return await axios.get(BANK_INFO_LIST + "/" + transfereeId);
  }

  public static async updateBankAccount(data: any) {
    return await axios.post(UPDATE_BANKING_ACCOUNT + "/" + data.id, data);
  }

  public static async setDismissedForIds(data: any) {
    return await axios.post(SET_DISMISS_FOR_IDS, data);
  }

  public static async deleteBankAccount(data: any) {
    return await axios.del(
      DELETE_BANKING_ACCOUNT + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async exportBankInfo(data: any) {
    const headers = {
      responseType: "blob",
    };
    return axios.post<any>(EXPORT_BANK_INFO, data, headers);
  }
}

export default bankingInformationService;
