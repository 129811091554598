import {
  DELETE_PROFILE_PICTURE,
  DELETE_STAFF_PROFILE_PICTURE,
  GET_ASSIGNEES_LIST,
  GET_STAFF_DETAILS,
  GET_USER_BY_ROLE,
  TOGGLE_STAFF_VISIBILITY,
  UPDATE_USER_PROFILE,
  CREATE_STAFF_URL,
  DELETE_STAFF_URL,
  UPDATE_STAFF_URL,
} from "../../resources/constants";
import axios from "..";

const notLogedIn = { error: "not logged in due to no network" };

export interface StaffPageState {
  data?: any;
  success?: boolean;
  error?: any;
  Environment?: any;
  userDetails: any;
}

const initialState: StaffPageState = {
  data: [],
  success: false,
  error: undefined,
  Environment: undefined,
  userDetails: null,
};

export default class StaffServices {
  public static async getUserByRole(filtersData: any) {
    try {
      const result: any = await axios.get(
        GET_USER_BY_ROLE + "?" + new URLSearchParams(filtersData).toString(),
      );
      return result?.data;
    } catch (error) {
      return error;
    }
  }

  public static async getAssignees(filtersData: any) {
    try {
      const result: any = await axios.get(
        GET_ASSIGNEES_LIST + "?" + new URLSearchParams(filtersData).toString(),
      );
      return result?.data;
    } catch (error) {
      return error;
    }
  }
  public static async getStaffDetails(data: any) {
    return await axios.get(GET_STAFF_DETAILS + "/" + data.staffId);
  }

  public static async createStaff(data: any) {
    return await axios.put(CREATE_STAFF_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async updateStaff(data: any) {
    return await axios.post(UPDATE_STAFF_URL, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  public static async updateUserProfile(data: any) {
    return await axios.post(UPDATE_USER_PROFILE, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async deleteProfilePicture(id: string) {
    return await axios.del(DELETE_STAFF_PROFILE_PICTURE + "/" + id);
  }

  public static async deleteStaff(data: any) {
    return await axios.del(DELETE_STAFF_URL + "/" + data.staffId);
  }

  public static async toggleStaff(id: any) {
    return await axios.put(TOGGLE_STAFF_VISIBILITY + "?staffId=" + id, {});
  }
}

//get exception details
