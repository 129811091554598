import axiosClient from "..";
import {
  CREATE_SUPPLIER_URL,
  DELETE_SUPPLIER,
  GET_SUPPLIER_URL,
  SUPPLIERS_LISTING_URL,
  SUPPLIER_USER_TOGGLE,
  UPDATE_SUPPLIER_URL,
} from "../../resources/constants";
import axios from "..";

class SupplierService {
  public static async getAllSuppliers(filters: any) {
    try {
      const result: any = await axiosClient.get(
        SUPPLIERS_LISTING_URL + "?" + new URLSearchParams(filters).toString(),
      );
      return result?.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public static async getSupplierDetails(data: any) {
    return await axios.get(GET_SUPPLIER_URL + "/" + data.supplierId);
  }

  public static async deleteSupplier(data: any) {
    return await axios.del(DELETE_SUPPLIER + "/" + data.supplierId);
  }

  public static async createSupplier(data: any) {
    if (data.id) return await axios.post(UPDATE_SUPPLIER_URL, data);
    else {
      delete data.id;
      return await axios.post(CREATE_SUPPLIER_URL, data);
    }
  }

  public static async toggleSupplierUser(id: any) {
    return await axios.put(
      SUPPLIER_USER_TOGGLE + "/?supplierContactId=" + id,
      {},
    );
  }
}

export default SupplierService;
