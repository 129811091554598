import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import divisionsService from "../../../services/Divisions/divisions";
import {
  ACTION_GET_DIVISION_FORM,
  ACTION_GET_INIT_CONFIG,
} from "../../../Actions/actions";

interface intialState {
  loading: boolean;
  error: null | string;
  data: any;
  form: any;
  fields: any;
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {},
  form: {},
  fields: {},
};

export const getDivisionForm = createAsyncThunk(
  ACTION_GET_DIVISION_FORM,
  async (data: any) => {
    return divisionsService.getDivisionForm(data);
  },
);

const getDivisionFormFullfield = (state: any, action: any) => {
  state.loading = false;
  state.form.data = action.payload?.data;
};

export const getTopDivisionForm = createAsyncThunk(
  "ACTION_GET_DIVISION_FORM_TOP",
  async (data: any) => {
    return divisionsService.getTopDivisionForm(data);
  },
);

const getTopDivisionFormFullfield = (state: any, action: any) => {
  state.loading = false;
  state.form.data = action.payload?.data;
  state.form.data = {
    ...state.form.data,
    divisionForm: {
      ...action.payload?.data?.clientDivisionForm,
      division: action.payload?.data?.clientDivisionForm?.client,
    },
  };
};

export const getInitConfig = createAsyncThunk(
  ACTION_GET_INIT_CONFIG,
  async (data: any) => {
    return divisionsService.getInitConfig(data);
  },
);

const getInitConfigFullfield = (state: any, action: any) => {
  state.loading = false;
  state.fields.data = action.payload?.data;
};

const divisionCRUDSlice = createSlice({
  name: "division",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getDivisionForm.fulfilled, getDivisionFormFullfield);
    builder.addCase(getTopDivisionForm.fulfilled, getTopDivisionFormFullfield);
    builder.addCase(getInitConfig.fulfilled, getInitConfigFullfield);

    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default divisionCRUDSlice.reducer;
export const { reset } = divisionCRUDSlice.actions;
