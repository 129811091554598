import {
  GET_CITY_URL,
  GET_COUNTRY_URL,
  GET_STATE_URL,
  GET_POLICY_BY_DIVISION_URL,
  GET_DIVISION_CONFIG_URL,
  GET_STATUS_LIST,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SEARCH_MILO_TRANSFEREE,
  GET_MILO_TRANSFEREE_DETAILS,
  CREATE_MILO_TRANSFEREE,
  UPDATE_USER_INFO,
  CLIENT_CONTACT_BY_ROLE,
  GET_CLIENT_CM_ROLE,
  UPDATE_APPROVER_STATUS,
} from "../resources/constants";
import axios from ".";

export default class addEmployeeService {
  public static async getPolicyByDivisionId(payload: any) {
    try {
      let url = "";
      if (payload?.divisionId)
        url = `${GET_POLICY_BY_DIVISION_URL}?divisionId=${payload.divisionId}&clientId=${payload.clientId}`;
      else url = `${GET_POLICY_BY_DIVISION_URL}?clientId=${payload.clientId}`;
      const result: any = await axios.get(url);
      return result?.data?.policyList;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async searchMiloEmployee(payload: any) {
    let { data }: { data: any } = await axios.get(
      SEARCH_MILO_TRANSFEREE + "?" + new URLSearchParams(payload).toString(),
    );
    return data;
  }

  public static async getDivisionConfig(
    clientId: string,
    active: boolean,
    divisionId?: string,
  ) {
    try {
      let params = new URLSearchParams({ clientId });
      if (divisionId && active && clientId)
        params = new URLSearchParams({
          clientId,
          divisionId,
          active: active.toString(),
        });
      else if (divisionId && clientId)
        params = new URLSearchParams({ clientId, divisionId });
      else if (active && clientId)
        params = new URLSearchParams({ clientId, active: active.toString() });
      const result: any = await axios.get(
        GET_DIVISION_CONFIG_URL + "?" + params,
      );
      return result?.data?.initConfig;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getCountriesWithType(type: string) {
    try {
      let response: any = await axios.get(`${GET_COUNTRY_URL}`);

      return response;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getStatesWithType(countryId: string, type: string) {
    try {
      let response: any = await axios.get(
        `${GET_STATE_URL}/?countryId=${countryId}`,
      );
      return { type: type, data: response.data.states };
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getCitiesWithType(
    countryId: string,
    stateId: string,
    type: string,
  ) {
    try {
      let response: any = await axios.get(
        `${GET_CITY_URL}/?countryId=${countryId}&stateId=${stateId}`,
      );
      return { type: type, data: response.data.cities };
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getStatusList(data: any) {
    try {
      let response: any = await axios.post(`${GET_STATUS_LIST}`, data);
      return response.data.response;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async createEmployee(payload: any) {
    try {
      if (payload?.user?.id) {
        let response: any = await axios.post(UPDATE_EMPLOYEE, payload);
        return response.data.response;
      }
      let response: any = await axios.put(CREATE_EMPLOYEE, payload);
      return response.data.response;
    } catch (error: any) {
      throw new Error(error?.detail);
    }
  }

  public static async createMiloEmployee(payload: any) {
    let response: any = await axios.put(CREATE_MILO_TRANSFEREE, payload);
    return response.data;
  }

  public static async getMiloTransfereeDetails(payload: any) {
    return await axios.get(
      GET_MILO_TRANSFEREE_DETAILS + "?fileNumber=" + payload,
    );
  }

  public static async updateUser(payload: any) {
    return await axios.post(UPDATE_USER_INFO, payload);
  }

  public static async clientContactByRole(payload: any) {
    return await axios.post(CLIENT_CONTACT_BY_ROLE, payload);
  }

  public static async getClientCMGroupRole(payload) {
    return await axios.post(GET_CLIENT_CM_ROLE, payload);
  }

  public static async updateApproverStatus(payload) {
    return await axios.post(UPDATE_APPROVER_STATUS, payload);
  }
}
