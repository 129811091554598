import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";

import AdHocService from "../../services/AdHoc/AdHoc";

const initialState: any = {
  template: {
    data: {},
    error: null,
    loading: false,
  },
  view: {
    data: {},
    error: null,
    loading: false,
  },
};

export const getAdHocTemplate = createAsyncThunk(
  "GET_ADHOC_TEMPLATE",
  async () => {
    const response = AdHocService.getAdHocTemplate();
    return response;
  },
);

export const getAdHoc = createAsyncThunk(
  "GET_ADHOC_VIEW",
  async (id: string | number) => {
    const response = AdHocService.getAdHocPolicy(id);
    return response;
  },
);

//create a slice for Client
const AdHocSlice = createSlice({
  name: "adHoc",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    //get template
    builder.addCase(getAdHocTemplate.pending, (state) => {
      state.template.loading = true;
      state.template.error = null;
      state.template.data = {};
    });

    builder.addCase(getAdHocTemplate.fulfilled, (state, action: any) => {
      state.template.loading = false;
      state.template.error = null;
      state.template.data = action.payload?.data;
    });

    builder.addCase(getAdHocTemplate.rejected, (state, action) => {
      state.template.loading = false;
      state.template.error = action.error;
      state.template.data = {};
    });

    //get view
    builder.addCase(getAdHoc.pending, (state) => {
      state.view.loading = true;
      state.view.error = null;
      state.view.data = {};
    });

    builder.addCase(getAdHoc.fulfilled, (state, action: any) => {
      state.view.loading = false;
      state.view.error = null;
      state.view.data = action.payload?.data;
    });

    builder.addCase(getAdHoc.rejected, (state, action) => {
      state.view.loading = false;
      state.view.error = action.error;
      state.view.data = {};
    });
  },
});

export default AdHocSlice.reducer;
export const { resetState } = AdHocSlice.actions;
