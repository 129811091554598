import axios from "..";
import {
  CREATE_RELO_EVENT_URL,
  GET_RELO_EVENT_URL,
  UPDATE_RELO_EVENT_URL,
  GET_RELO_EVENT_LIST_URL,
  GET_MILO_EVENT_LIST_URL,
  GET_TIMELINE_MANAGEMENT_URL,
  CREATE_TIMELINE_MANAGEMENT_URL,
  UPDATE_TIMELINE_MANAGEMENT_URL,
  GET_MOVE_IN_DATE_URL,
  UPDATE_MOVE_IN_DATE_URL,
  GET_TRANSFEREE_FROM_MILO_URL,
} from "../../resources/constants";

export default class timelineService {
  public static async getTimelineObject(data: any) {
    return await axios.get(
      GET_TIMELINE_MANAGEMENT_URL + "/?" + new URLSearchParams(data),
    );
  }

  public static async createTimelineObject(data: any) {
    return await axios.post(CREATE_TIMELINE_MANAGEMENT_URL, data);
  }

  public static async updateTimelineObject(data: any) {
    return await axios.put(UPDATE_TIMELINE_MANAGEMENT_URL, data);
  }

  public static async createReloEvent(data: any) {
    return await axios.post(CREATE_RELO_EVENT_URL, data);
  }

  public static async updateReloEvent(data: any) {
    return await axios.put(UPDATE_RELO_EVENT_URL, data);
  }

  public static async getReloEvent(data: any) {
    return await axios.get(
      GET_RELO_EVENT_URL + "/?" + new URLSearchParams(data),
    );
  }

  public static async getTransfereeFromMilo(data: any) {
    return await axios.get(
      GET_TRANSFEREE_FROM_MILO_URL + "?" + new URLSearchParams(data),
    );
  }

  public static async getMoveInDate(data: any) {
    return await axios.get(
      GET_MOVE_IN_DATE_URL + "/?" + new URLSearchParams(data),
    );
  }

  public static async updateMoveInDate(data: any) {
    return await axios.post(UPDATE_MOVE_IN_DATE_URL, data);
  }

  public static async getMiloEvents(data: any) {
    return await axios.get(
      GET_MILO_EVENT_LIST_URL + "/?" + new URLSearchParams(data),
    );
  }

  public static async getReloEvents(id: string) {
    return await axios.get(GET_RELO_EVENT_LIST_URL + "/" + id);
  }
}
