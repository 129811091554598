import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import costEstimatesViewService from "../../../services/CostEstimates/CostEstimatesView";
import { ACTION_COST_ESTIMATES_VIEW } from "../../../Actions/actions";

interface intialState {
  loading: boolean;
  error: null | string;
  data: any;
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {},
};

export const costEstimatesView = createAsyncThunk(
  ACTION_COST_ESTIMATES_VIEW,
  async (id: string) => {
    return costEstimatesViewService.getCostEstimatesView(id);
  },
);

const costEstimatesFullfield = (state: any, action: any) => {
  state.loading = false;
  state.data = action.payload?.data;
};

const costEstimatesViewSlice = createSlice({
  name: "costEstimatesView",
  initialState,
  reducers: {
    deleteApprover(state, action) {
      costEstimatesViewService.deleteApprover(action.payload);
      state.data.approvals = state.data.approvals.filter(
        (approver: any) => approver.id !== action.payload,
      );
    },
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(costEstimatesView.fulfilled, costEstimatesFullfield);
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action?.error?.message;
      },
    );
  },
});

export const { deleteApprover, resetState } = costEstimatesViewSlice.actions;
export default costEstimatesViewSlice.reducer;
