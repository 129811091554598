import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import expenseAuthorizationService from "../../services/ExpenseAuthorization/ExpenseAuthorization";

const initialState: any = {
  authorization: {
    data: {},
    error: null,
    loading: false,
  },
  milo: {
    data: {},
    error: null,
    loading: false,
  },
  resolved: {
    data: {},
    error: null,
    loading: false,
  },
};

export const getExpenseAuthorization = createAsyncThunk(
  "GET_EXPENSE_AUTHORIZATION_LIST",
  async (id: any) => {
    return expenseAuthorizationService.getExpenseAuthorization(id);
  },
);

export const getMiloExpense = createAsyncThunk(
  "GET_MILO_EXPENSE_LIST",
  async (id: any) => {
    return expenseAuthorizationService.getMiloExpense(id);
  },
);

export const getResolvedExpense = createAsyncThunk(
  "GET_RESOLVED_EXPENSE_LIST",
  async (id: any) => {
    return expenseAuthorizationService.getResolvedExpense(id);
  },
);

//create a slice for Client
const ExpenseAuthorization = createSlice({
  name: "expenseAuthorization",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getExpenseAuthorization.pending, (state) => {
      state.authorization.loading = true;
      state.authorization.error = null;
      state.authorization.data = {};
    });

    builder.addCase(getExpenseAuthorization.fulfilled, (state, action: any) => {
      state.authorization.loading = false;
      state.authorization.error = null;
      state.authorization.data = action.payload?.data;
    });

    builder.addCase(getExpenseAuthorization.rejected, (state, action) => {
      state.authorization.loading = false;
      state.authorization.error = action?.error?.message;
      state.authorization.data = {};
    });

    builder.addCase(getMiloExpense.pending, (state) => {
      state.milo.loading = true;
      state.milo.error = null;
      state.milo.data = {};
    });

    builder.addCase(getMiloExpense.fulfilled, (state, action: any) => {
      state.milo.loading = false;
      state.milo.error = null;
      state.milo.data = action.payload?.data;
    });

    builder.addCase(getMiloExpense.rejected, (state, action) => {
      state.milo.loading = false;
      state.milo.error = action?.error?.message;
      state.milo.data = {};
    });

    builder.addCase(getResolvedExpense.pending, (state) => {
      state.resolved.loading = true;
      state.resolved.error = null;
      state.resolved.data = {};
    });

    builder.addCase(getResolvedExpense.fulfilled, (state, action: any) => {
      state.resolved.loading = false;
      state.resolved.error = null;
      state.resolved.data = action.payload?.data;
    });

    builder.addCase(getResolvedExpense.rejected, (state, action) => {
      state.resolved.loading = false;
      state.resolved.error = action?.error?.message;
      state.resolved.data = {};
    });
  },
});

export default ExpenseAuthorization.reducer;
