import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financialOverviewListingService from "../../services/FinancialOverviewListing/FinancialOverviewListing";

const initialState = {
  financialOverviewListing: {
    table: [],
    count: 0,
    page: 1,
  },
  loading: false,
  error: null,
};

export const getFinancialsList = createAsyncThunk(
  "GET_FINANCIALS_LIST",
  async (filtersData: any) => {
    const response =
      await financialOverviewListingService.getFinancialsList(filtersData);
    return response;
  },
);

const FinancialOverviewListingSlice = createSlice({
  name: "FinancialOverviewListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancialsList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.financialOverviewListing.table =
          action.payload?.data?.financialOverviewList;
        state.financialOverviewListing.count =
          action.payload?.data?.financialOverviewCount;
        state.financialOverviewListing.page = action.payload.page;
      })
      .addCase(getFinancialsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message;
      });
  },
});

export default FinancialOverviewListingSlice.reducer;
