import { ArgsProps } from "antd/es/notification";
import SVGIcon from "../../components/SVGIcon/SVGIcon";
import {
  logoutIcon,
  helpIcon,
  supportTeamicon,
  transferees,
  userProfile,
} from "../../resources/SVGImages/dashboard";

import expenseStatusChangeIcon from "../../resources/images/notification/expenseStatusChangeIcon.svg";
import tlrReviewIcon from "../../resources/images/notification/tlrReviewIcon.svg";
import transfereeRequestIcon from "../../resources/images/notification/transfereeRequestIcon.svg";
import defautlNotiIcon from "../../resources/images/notification/default.svg";

const usersIcon = (
  <SVGIcon className="dashboardMenuIcon" childRen={transferees} />
);

export const notificationIcons = {
  TLR_REVIEW: tlrReviewIcon,
  EXCEPTION_STATUS_CHANGE: expenseStatusChangeIcon,
  TRANSFEREE_REQUEST: transfereeRequestIcon,
};

export const AdminOptions = [
  {
    key: "user-profile",
    label: "My Profile",
    icon: userProfile,
    id: "user-profile",
  },
  {
    key: "support",
    label: "Contact Support",
    icon: supportTeamicon,
    id: "support",
  },
  { key: "logout", label: "Logout", icon: logoutIcon, id: "logout" },
];

export const getNotificationContent = (
  sourceType: string,
  title: string,
  message: string,
  link?: string | null,
): ArgsProps => {
  return {
    placement: "top",
    className: "notificationPopupX",
    message: (
      <div className="notificationPopup">
        <div className="icon">
          <img
            src={sourceType ? notificationIcons[sourceType] : defautlNotiIcon}
          />
        </div>
        <div className="data">
          <h2>{title}</h2>
          <p className="description">{message}</p>
          {link && (
            <p className="description">
              <span
                className="greenUnder oneLineOverflow"
                onClick={() => {
                  if (link.includes("http")) {
                    window.open(link, "_blank");
                  } else {
                    window.open(`http://${link}`, "_blank");
                  }
                }}
              >
                {link}
              </span>
            </p>
          )}
          {/* <p className="note"><em>*Expense for cab transport</em></p> */}
        </div>
        {/* <div className="name">Hannah Blue</div> */}
      </div>
    ),
  };
};

export const ClientOptions = [
  {
    key: "user-profile",
    label: "My Profile",
    icon: userProfile,
    id: "user-profile",
  },
  { key: "help", label: "Help Center", icon: helpIcon, id: "help" },
  {
    key: "support",
    label: "Contact Support",
    icon: supportTeamicon,
    id: "support",
  },
  {
    key: "support-team",
    label: "Support Team",
    icon: usersIcon,
    id: "support-team",
  },
  { key: "logout", label: "Logout", icon: logoutIcon, id: "logout" },
];
export const TransfereeOptions = [
  {
    key: "user-profile",
    label: "My Profile",
    icon: userProfile,
    id: "user-profile",
  },
  // { key: "help", label: "Help Center", icon: helpIcon, id: "help" },
  {
    key: "support",
    label: "Contact Support",
    icon: supportTeamicon,
    id: "support",
  },
  { key: "logout", label: "Logout", icon: logoutIcon, id: "logout" },
];

export const supplierOptions = [
  {
    key: "user-profile",
    label: "My Profile",
    icon: userProfile,
    id: "user-profile",
  },
  {
    key: "support",
    label: "Contact Support",
    icon: supportTeamicon,
    id: "support",
  },
  { key: "logout", label: "Logout", icon: logoutIcon, id: "logout" },
];
