import { GET_FINANCIALS_LIST } from "../../resources/constants";
import AxiosClient from "..";
export interface financialOverviewListingPageState {
  data?: any;
  success?: boolean;
  error?: any;
  financialOverviewListingPageState?: any;
}

export default class financialOverviewListingService {
  static async getFinancialsList(filtersData: any) {
    return await AxiosClient.get(
      GET_FINANCIALS_LIST + "?" + new URLSearchParams(filtersData).toString(),
    );
  }
}
