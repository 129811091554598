import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import loginReducer from "./Login/slice";
import transfereeSlice from "./Transferees/slice";
import transfereeProfileSlice from "./TransfereeProfile/slice";
import addEmployeeSlice from "./AddEmployee/slice";
import dashBoardSlice from "./Dashboard/slice";
import exceptions from "./Exceptions/slice";
import divisions from "./Divisions/slice";
import displayConfigsSlice from "./DisplayConfigs/slice";
import clients from "./Client/slice";
import costEstimatesListingSlice from "./CostEstimates/CostEstimatesListing/slice";
import filters from "./Filters/Filters";
import costEstimatesViewSlice from "./CostEstimates/CostEstimatesView/slice";
import costEstimatesSlice from "./CostEstimates/CostEstimates/slice";
import countryStateCitySlice from "../store/CountryStateCity/slice";
import divisionViewSlice from "./Divisions/DivisionView/slice";
import divisionCRUDSlice from "./Divisions/DivisionCRUD/slice";
import bankingInformationSlice from "./BankingInformation/slice";
import timelineSlice from "./Timeline/slice";
import fileShareSlice from "./FileShare/slice";
import reports from "./Reports/slice";
import tempLiving from "./TempLiving/slice";
import financialOverviewSlice from "./FinancialOverview/slice";
import ExpenseSubmission from "./ExpenseSubmission/slice";
import clientContactSlice from "./ClientContact/slice";
import clientMemberSlice from "./ClientMember/slice";
import criGroupSlice from "./CRIGroup/slice";
import globalLoaderSlice from "./Loader/slice";
import CostEstimatorConfig from "./CostEstimates/CostEstimateConfigs/slice";
import financialOverviewListingSlice from "./FinancialOverviewListing/slice";
import staff from "./Staff/slice";
import expenseCategory from "./ExpenseCategory/slice";
import Suppliers from "./Suppliers/slice";
import Vendors from "./Vendors/slice";
import policySlice from "./Policy/slice";
import WhatsNewSlice from "./WhatsNew/slice";
import AdHocSlice from "./AdHoc/slice";
import GlobalVars from "./GlobalVars/slice";
import SidebarMenu from "./SidebarMenu/slice";
import SelfGuidedSlice from "./SelfGuided/slice";
import ExpenseAuthorizationSlice from "./ExpenseAuthorization/slice";
import SupplierPortalSlice from "./SupplierPortal/slice";
import Authorized from "./Authorized/slice";
import NotificationSlice from "./Notification/slice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    transferee: transfereeSlice,
    timeline: timelineSlice,
    addEmployee: addEmployeeSlice,
    dashBoard: dashBoardSlice,
    exceptions: exceptions,
    transfereeProfile: transfereeProfileSlice,
    divisions: divisions,
    clients: clients,
    expenseCategory: expenseCategory,
    staff: staff,
    displayConfig: displayConfigsSlice,
    costEstimatesListing: costEstimatesListingSlice,
    filters: filters,
    costEstimatesView: costEstimatesViewSlice,
    costEstimates: costEstimatesSlice,
    countryStateCity: countryStateCitySlice,
    divisionView: divisionViewSlice,
    divisionCRUD: divisionCRUDSlice,
    bankingInformation: bankingInformationSlice,
    fileShare: fileShareSlice,
    reports: reports,
    tempLiving: tempLiving,
    loader: globalLoaderSlice,
    financialOverview: financialOverviewSlice,
    financialOverViewListing: financialOverviewListingSlice,
    expenseSubmission: ExpenseSubmission,
    clientContact: clientContactSlice,
    clientMember: clientMemberSlice,
    criGroup: criGroupSlice,
    costEstimatesConfig: CostEstimatorConfig,
    suppliers: Suppliers,
    vendors: Vendors,
    policy: policySlice,
    whatsNew: WhatsNewSlice,
    adHoc: AdHocSlice,
    globalVars: GlobalVars,
    siderbarMenu: SidebarMenu,
    selfGuided: SelfGuidedSlice,
    expenseAuthorization: ExpenseAuthorizationSlice,
    supplierPortal: SupplierPortalSlice,
    authorized: Authorized,
    notification: NotificationSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const storeDispatch = store.dispatch;
export default store;
