import { useAppSelector } from "../store/hooks";

export const useHasRole = () => {
  const login = useAppSelector((state) => state.login);

  const userRoles = login.user?.userRoles?.map((role: any) => role.authority);

  const hasRole = (role: string) => {
    if (login.user) {
      return (
        userRoles?.includes(role) ||
        userRoles?.includes("ROLE_" + role?.toUpperCase?.())
      );
    }
    return false;
  };

  const mustRoles = (roles: string[]) => {
    return roles.every((role) => hasRole(role));
  };

  const someRoles = (roles: string[]) => {
    return roles.some((role) => hasRole(role));
  };

  return { hasRole, mustRoles, someRoles };
};
