import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";

import ExpenseCategoryServices from "../../services/ExpenseCategory/ExpenseCategory";

const initialState: any = {
  expenseCategory: {
    data: [],
  },
  expenseTypes: [],
  expenseCategoryDetails: {},
  expenseTypeDetails: {},
  loading: false,
  error: null,
};

export const getExpenseCategory = createAsyncThunk(
  "GET_EXPENSE_CATEGORY",
  async () => {
    return await ExpenseCategoryServices.getExpenseCategory();
  },
);

export const getExpenseType = createAsyncThunk("GET_EXPENSE_TYPE", async () => {
  return await ExpenseCategoryServices.getExpenseType();
});

export const getExpenseCategoryDetails = createAsyncThunk(
  "GET_EXPENSE_CATEGORY_DETAILS",
  async (data: any) => {
    return await ExpenseCategoryServices.getExpenseCategoryDetails(data);
  },
);

export const getExpenseTypeDetails = createAsyncThunk(
  "GET_EXPENSE_TYPE_DETAILS",
  async (data: any) => {
    return await ExpenseCategoryServices.getExpenseTypeDetails(data);
  },
);

const ExpenseCategorySlice = createSlice({
  name: "expenseCategory",
  initialState,
  reducers: {
    resetExpenseCategory: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getExpenseCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExpenseCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.expenseCategory.data = action.payload.expenseCategories;
    });
    builder.addCase(getExpenseCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });

    builder.addCase(getExpenseType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExpenseType.fulfilled, (state, action) => {
      state.loading = false;
      state.expenseTypes = action.payload?.data.expenseCategories;
    });
    builder.addCase(getExpenseType.rejected, (state, action) => {
      state.loading = false;
      state.expenseTypes = [];
      state.error = action?.error?.message;
    });
    builder.addCase(getExpenseCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getExpenseCategoryDetails.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.expenseCategoryDetails = action.payload?.data.expenseCategory;
      },
    );
    builder.addCase(getExpenseCategoryDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });

    builder.addCase(getExpenseTypeDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getExpenseTypeDetails.fulfilled, (state, action: any) => {
      state.loading = false;
      state.expenseTypeDetails = action?.payload.data.ExpenseType;
    });
    builder.addCase(getExpenseTypeDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
  },
});

export const { resetExpenseCategory } = ExpenseCategorySlice.actions;
export default ExpenseCategorySlice.reducer;
