import { Button, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import CustomTypography from "../CustomTypoGraphy/CustomTypoGraphy";
import { logout } from "../../utils/logout";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../store/Login/slice";
import sessionTimeout from "../../resources/images/session-timeout.svg";

const SessionPopup = () => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkSessionInterval = setInterval(() => {
      if (localStorage.getItem("lastTime")) {
        const lastTime = window.localStorage.getItem("lastTime");
        const currentTime = new Date().getTime();
        const expireTime = process.env.REACT_APP_TOKEN_EXPIRY_SECONDS || 600;

        if (currentTime > parseInt(lastTime!) + 1000 * +expireTime) {
          setModal(true);
        }

        if (currentTime > parseInt(lastTime!) + 1000 * 90 * 60) {
          onCancel();
        }
      }
    }, 1000);
    return () => {
      clearInterval(checkSessionInterval);
    };
  }, []);

  const onOk = () => {
    setModal(false);
    dispatch(getUserInfo());
  };

  const onCancel = () => {
    setModal(false);
    logout();
  };

  return (
    <Modal
      open={modal}
      onOk={onOk}
      width={500}
      onCancel={onCancel}
      footer={[<></>, <></>]}
    >
      <img
        src={sessionTimeout}
        style={{
          width: "80%",
          maxWidth: "150px",
          display: "block",
          margin: "20px auto 0",
        }}
      />

      <CustomTypography
        className="commonH center stdSTop"
        type="title"
        level={2}
      >
        Do you want to continue the session?
      </CustomTypography>

      <Space size="middle" className="spaceEnd stdMTop">
        <Button className="modalRedBtn" onClick={onCancel}>
          Logout
        </Button>
        <Button className="modalBlueBtn" onClick={onOk}>
          Continue
        </Button>
      </Space>
    </Modal>
  );
};

export default SessionPopup;
