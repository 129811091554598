import axios from "..";
import {
  GET_FILE_SHARE_FOLDERS,
  CREATE_FILE_SHARE_FOLDER,
  UPDATE_FILE_SHARE_FOLDER,
  GET_DROPBOX_DETAILS,
  GET_FILE_LIST,
  UPLOAD_FILES_DROPBOX,
  DELETE_FILE_DROPBOX,
  DOWNLOAD_DROPBOX_FILE,
} from "../../resources/constants";

class fileShareService {
  public static async getFileShareFolders(data: any) {
    return await axios.post(GET_FILE_SHARE_FOLDERS, data);
  }

  public static async createFileShareFolder(data: any) {
    return await axios.put(CREATE_FILE_SHARE_FOLDER, data);
  }

  public static async updateFileShareFolder(data: any) {
    return await axios.post(UPDATE_FILE_SHARE_FOLDER, data);
  }

  public static async getDropboxDetails(id: string) {
    return await axios.get(GET_DROPBOX_DETAILS + "/" + id);
  }

  public static async downloadFile(id: string) {
    return await axios.get(DOWNLOAD_DROPBOX_FILE + "/" + id);
  }

  public static async getFileList(data: any) {
    return await axios.get(
      GET_FILE_LIST + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async uploadFiles(id: string, data: any) {
    return await axios.post(UPLOAD_FILES_DROPBOX + "/" + id, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async deleteFiles(data: any) {
    return await axios.del(
      DELETE_FILE_DROPBOX + "/?" + new URLSearchParams(data).toString(),
    );
  }
}

export default fileShareService;
