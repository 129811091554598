import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fileShareService from "../../services/FileShare/FileShare";

export const initialState: any = {
  folders: {
    error: null,
    loading: true,
    data: {},
  },
  files: {
    error: null,
    loading: true,
    data: {},
  },
};

// ACTION_GET_BANKING_ACCOUNTS
export const getFileShareFolders = createAsyncThunk(
  "ACTION_GET_FILE_SHARE_FOLDERS",
  async (data: any) => {
    return fileShareService.getFileShareFolders(data);
  },
);

const getFileShareFoldersFullfield = (state: any, action: any) => {
  state.folders.loading = false;
  state.folders.data = action.payload?.data;
  state.folders.error = null;
};
const getFileShareFoldersPending = (state: any, action: any) => {
  state.folders.loading = true;
  state.folders.error = action?.error?.message;
};
const getFileShareFoldersRejected = (state: any, action: any) => {
  state.folders.loading = false;
  state.folders.error = action?.error?.message;
};

// ACTION_GET_FILES
export const getFileList = createAsyncThunk(
  "ACTION_GET_FILES",
  async (data: any) => {
    return fileShareService.getFileList(data);
  },
);

const getFileListFullfield = (state: any, action: any) => {
  state.files.loading = false;
  state.files.data = action.payload?.data;
  state.files.error = null;
};
const getFileListPending = (state: any, action: any) => {
  state.files.loading = true;
  state.files.error = action?.error?.message;
};
const getFileListRejected = (state: any, action: any) => {
  state.files.loading = false;
  state.files.error = action?.error?.message;
};

const fileShareSlice = createSlice({
  name: "FileShare",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // ACTION_GET_BANKING_ACCOUNTS
    builder.addCase(
      getFileShareFolders.fulfilled,
      getFileShareFoldersFullfield,
    );
    builder.addCase(getFileShareFolders.pending, getFileShareFoldersPending);
    builder.addCase(getFileShareFolders.rejected, getFileShareFoldersRejected);

    // ACTION_GET_FILES
    builder.addCase(getFileList.fulfilled, getFileListFullfield);
    builder.addCase(getFileList.pending, getFileListPending);
    builder.addCase(getFileList.rejected, getFileListRejected);
  },
});

export default fileShareSlice.reducer;
