import React from "react";
import { Button } from "antd";

interface ButtonProps {
  onClick?: () => void;
  type?: "primary" | "ghost" | "dashed" | "link" | "text" | "default";
  disabled?: boolean;
  loading?: boolean;
  size?: "large" | "middle" | "small";
  htmlType?: "button" | "submit" | "reset";
  icon?: React.ReactNode;
  className?: string;
  btnText?: string;
  ghost?: boolean;
  Children?: React.ReactNode;
  color?: string;
  id?: string;
}

const FormButton: React.FC<ButtonProps> = ({
  onClick,
  type = "primary",
  disabled = false,
  loading = false,
  size = "middle",
  htmlType = "submit",
  icon,
  className,
  btnText,
  id,
  ghost,
  Children,
  color,
}) => {
  return (
    <Button
      onClick={onClick}
      ghost={ghost}
      style={
        {
          borderColor: color,
          color,
          "--accent-color": color,
        } as React.CSSProperties
      }
      type={
        type as "primary" | "dashed" | "link" | "text" | "default" | undefined
      }
      disabled={disabled}
      loading={loading}
      size={size}
      htmlType={htmlType}
      icon={icon}
      id={id}
      className={className}
    >
      {btnText}
      {Children}
    </Button>
  );
};

export default FormButton;
