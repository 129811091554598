import { Button, Space } from "antd";
import "./ConcentPopup.scss";
import reloImg from "../../resources/images/cri.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import transfereeServices from "../../services/transferees";
import { logout } from "../../utils/logout";

const ConcentPopup = () => {
  const [visible, setVisible] = useState(false);
  const user = useSelector((state: any) => state.login?.user);

  const onOk = () => {
    transfereeServices
      .setPIIAcknowledgement({
        acknowledgement: true,
      })
      .then((res) => {})
      .finally(() => {
        window.location.reload();
      });
  };

  const onCancel = () => {
    logout();
  };

  useEffect(() => {
    if (user?.role === "transferee" && !user?.piiAck) {
      setVisible(true);
    }
  }, [user]);

  return (
    <>
      {visible && (
        <>
          <div className="concent-overlay"></div>
          <div className="concent-popup">
            <div className="head">
              <img src={reloImg} alt="" />
              <h2>PII Acknowledgement</h2>
            </div>

            <div className="data">
              <h3>
                Disclosure of processing activities and consent to process
                personal data
              </h3>
              <p>
                <b>Corporate Relocation International</b> wishes to disclose the
                manner in which we will collect, process, store, disclose and
                transfer your personal data in order to provide your
                employer-contracted relocation services. References to "we" and
                "our" in this disclosure document refers to Corporate Relocation
                International and/or any of our affiliated relocation companies
                ("CRI").
              </p>
              <p>
                <b>
                  Personal Data or Personally Identifiable Information (PII)
                </b>
                <br /> The terms "personal data" or "personally identifiable
                information" (PII) refer to personal details about you and/or
                your family members that you submit to us in order to provide
                the requested relocation services on your behalf. Such PII may
                include, without limitation, your name, address, phone number,
                e-mail address, national identification number, passport
                details, or any other data relating to you or your family
                members that may constitute personal data under any law. This
                information will most likely be collected directly from you or
                from your employer and may include special categories of data,
                such as your ethnicity, religion, and/or health condition, only
                if required to provide the contracted services. The collected
                personal data will be securely stored by CRI in any practical
                but secure media or format including computerized or electronic
                records as well as paper-based files that may be created or made
                available in connection with your relocation.
              </p>
              <p>
                <b>Processing of Personal Data</b>
                <br /> We will collect, process, store, transfer and delete your
                PII only for the specific purpose of providing your contracted
                services, including the following: (a) arranging your relocation
                and storage of your household goods; (b) processing expense
                reimbursements; (c) home sales, purchases, and rentals, (d)
                description and explanation of your benefits; (e) immigration
                services; (f) cultural assistance/language training; and (g)
                generating records and reports relating to the contracted
                services. CRI will not use your PII for any purpose other than
                the contracted services as described in this disclosure
                document, unless it is required or authorized by applicable law.
              </p>
              <p>
                <b>Transfer and Protection of Personal Data</b>
                <br /> In order to provide the contracted services, we may
                transfer PII to our offices, to our employees, and to our
                service providers wherever located throughout the world,
                including in countries outside of your country of residence. PII
                may be securely stored in databases outside of your country of
                residence and/or on secure cloud-based servers hosted by
                reputable cloud service providers. CRI's global headquarters is
                located in Carrollton, TX. As a result, personal data will be
                stored on CRI's secure servers in that country to enable CRI to
                generate management reporting for your employer and necessary
                business reports for internal consumption only. In some
                instances, we may disclose limited PII on a need to know basis
                to CRI's qualified service partners who are directly involved in
                the provision of the contracted services. CRI may also share
                information about you with CRI's auditors, bankers, attorneys,
                and other professionals. All of CRI's service partners and
                vendors are contractually obligated to protect PII at all times
                and have agreed to protect all personal data with appropriate
                technical and organizational measures. CRI will retain your
                personal data only for as long as necessary and in accordance
                with your employer's requirements or in accordance with CRI's
                data retention policy. In the event that you will be receiving
                moving services and you request or agree to a virtual video
                consultation with one of our consultants to review the personal
                effects that you will be transporting, the session will be
                recorded and stored for up to 6 months after service completion.
              </p>
              <p>
                <b>Your rights</b>
                <br /> You have the right to access PII relating to you and make
                corrections to any inaccurate information about you. You also
                have the right to withdraw your consent at any time. Requests
                should be made in writing and submitted either to the Mobility
                Advisor assisting with your relocation services or to Corporate
                Relocation international at 301 International Parkway, Suite
                410, Flower Mound, TX 75022, marked for the attention of Data
                Protection Officer
              </p>
              <p>
                I have reviewed the above disclosure by Corporate Relocation
                International, and I hereby consent for CRI to process my
                (and/or my family's) personal data.
              </p>
              {/* <p>Signature: _______________________________ <br />
							Print Name: _______________________________ <br />
							Date: _______________________________</p> */}
              <p>
                If you do not consent to the above, please do not sign this
                document. Please let your CRI contact know of your decision
                immediately.{" "}
              </p>

              <div className="blueSignature">
                <em>Wherever Life Takes You</em>
                <b>
                  <a href="https://www.corprelo.com/" target="_blank">
                    www.corprelo.com
                  </a>
                </b>
              </div>
            </div>
            <Space size="middle" className="spaceEnd stdSTop">
              <Button className="modalRedBtn" onClick={onCancel}>
                Reject
              </Button>
              <Button className="modalBlueBtn" onClick={onOk}>
                Accept
              </Button>
            </Space>
          </div>
        </>
      )}
    </>
  );
};

export default ConcentPopup;
