import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import divisionsService from "../../../services/Divisions/divisions";
import {
  ACTION_GET_CONSULTANT_LIST,
  ACTION_GET_DIVISION_VIEW,
  ACTION_GET_POLICY_LIST,
} from "../../../Actions/actions";

interface intialState {
  loading: boolean;
  error: null | string;
  data: any;
  policyData: any;
  consultantData: any;
  topLevelData: any;
}

export const initialState: intialState = {
  loading: true,
  error: null,
  data: {},
  policyData: [],
  consultantData: [],
  topLevelData: {},
};

export const divisionView = createAsyncThunk(
  ACTION_GET_DIVISION_VIEW,
  async (data: any) => {
    return divisionsService.getDivisionView(data);
  },
);

export const policyList = createAsyncThunk(
  ACTION_GET_POLICY_LIST,
  async (data: any) => {
    return divisionsService.getPolicyList(data);
  },
);
export const consultantList = createAsyncThunk(
  ACTION_GET_CONSULTANT_LIST,
  async () => {
    return divisionsService.getConsultantList();
  },
);

const divisionViewFullfield = (state: any, action: any) => {
  state.loading = false;
  state.data = action.payload?.data;
};

const policyListFullfield = (state: any, action: any) => {
  state.loading = false;
  state.policyData = action.payload?.data;
};
const consultantListFullfield = (state: any, action: any) => {
  state.loading = false;
  state.consultantData = action.payload?.data;
};

export const divisionTopView = createAsyncThunk(
  "ACTION_GET_DIVISION_VIEW_TOP",
  async (data: any) => {
    return divisionsService.getTopDivision(data);
  },
);

const divisionTopViewFullfield = (state: any, action: any) => {
  state.loading = false;
  state.data = action.payload?.data;
  state.data = {
    ...state?.data,
    divisionDetails: {
      ...state?.data?.clientDivisionDetails,
      division: {
        ...state?.data?.clientDivisionDetails?.client,
        clientId: state?.data?.clientDivisionDetails?.client?.id,
      },
    },
  };
};

const divisionViewSlice = createSlice({
  name: "division",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(divisionView.fulfilled, divisionViewFullfield);
    builder.addCase(policyList.fulfilled, policyListFullfield);
    builder.addCase(consultantList.fulfilled, consultantListFullfield);
    builder.addCase(divisionTopView.fulfilled, divisionTopViewFullfield);
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default divisionViewSlice.reducer;
export const { reset } = divisionViewSlice.actions;
