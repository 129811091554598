import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACTION_COST_ESTIMATES_CONFIG_BY_ID,
  ACTION_COST_ESTIMATES_CONFIGS_LISTING,
} from "../../../Actions/actions";
import costEstimatesConfigService from "../../../services/CostEstimates/Config/CostEstimatesConfig";

interface initialStateParams {
  loading: boolean;
  error: null | string;
  data: {
    table: any[];
    count?: number;
  };
  configDetails: any;
}

export const initialState: initialStateParams = {
  loading: true,
  error: null,
  data: {
    table: [],
    count: 0,
  },
  configDetails: {},
};

export const getCostEstimatesConfigsListing = createAsyncThunk(
  ACTION_COST_ESTIMATES_CONFIGS_LISTING,
  async (params: any) => {
    return costEstimatesConfigService.getCostEstimateConfigs(params);
  },
);

//get constEstimatesConfig by id
export const getCostEstimatesConfigById = createAsyncThunk(
  ACTION_COST_ESTIMATES_CONFIG_BY_ID,
  async (costEstimatorId: string) => {
    return costEstimatesConfigService.getCostEstimateConfigById(
      costEstimatorId,
    );
  },
);

const costEstimatesConfigFullFilled = (state: any, action: any) => {
  state.loading = false;
  state.data.table = action.payload?.data.costEstimatorIdList;
  state.data.count = action.payload?.data.count;
};

const getCostEstimatesConfigFullFilled = (state: any, action: any) => {
  state.loading = false;
  state.configDetails = action.payload?.data;
};

const costEstimatesConfigSlice = createSlice({
  name: "costEstimatesListing",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(
      getCostEstimatesConfigsListing.fulfilled,
      costEstimatesConfigFullFilled,
    );
    builder.addCase(
      getCostEstimatesConfigById.fulfilled,
      getCostEstimatesConfigFullFilled,
    );
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/pending") || action.type.endsWith("/rejected"),
      (state, action) => {
        state.loading = action.meta.requestStatus === "pending";
        state.error = action.error ? action?.error?.message : null;
      },
    );
  },
});

export default costEstimatesConfigSlice.reducer;
export const { resetState } = costEstimatesConfigSlice.actions;
