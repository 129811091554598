import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bankingInformationService from "../../services/BankingInformation/bankingInformation";

export const initialState: any = {
  accounts: {
    error: null,
    loading: true,
    data: {},
  },
  view: {
    error: null,
    loading: true,
    data: {},
  },
  bankInfo: {
    error: null,
    loading: false,
    data: {},
  },
  dismiss: {
    error: null,
    loading: false,
    data: {},
  },
  bankInfoUpdates: {
    error: null,
    table: [],
  },
  bankInfoList: {
    error: null,
    data: [],
    loading: false,
  },
};

// ACTION_GET_BANKING_ACCOUNTS
export const getbankingAccounts = createAsyncThunk(
  "ACTION_GET_BANKING_ACCOUNTS",
  async (id: string) => {
    return bankingInformationService.getBankingAccounts(id);
  },
);
const getbankingAccountsFullfield = (state: any, action: any) => {
  state.accounts.loading = false;
  state.accounts.data = action.payload?.data;
  state.view.error = null;
};
const getbankingAccountsPending = (state: any, action: any) => {
  state.accounts.loading = true;
  state.accounts.error = action?.error?.message;
};
const getbankingAccountsRejected = (state: any, action: any) => {
  state.accounts.loading = false;
  state.accounts.error = action?.error?.message;
};

//bank info updates list

export const getBankInfoUpdates = createAsyncThunk(
  "ACTION_GET_BANK_INFO_UPDATES_LIST",
  async () => {
    return bankingInformationService.getBankInfoUpdates();
  },
);
const getBankInfoUpdatesFullfifield = (state: any, action: any) => {
  state.bankInfoUpdates.loading = false;
  state.bankInfoUpdates.table = action.payload?.data.bankInfoUpdates;
  state.view.error = null;
};
const getBankInfoUpdatesPending = (state: any, action: any) => {
  state.bankInfoUpdates.loading = true;
  state.bankInfoUpdates.error = action?.error?.message;
};
const getBankInfoUpdatesRejected = (state: any, action: any) => {
  state.bankInfoUpdates.loading = false;
  state.bankInfoUpdates.error = action?.error?.message;
};

//bank info list

export const getBankInfoList = createAsyncThunk(
  "ACTION_GET_BANK_INFO_LIST",
  async (transfereeId: string) => {
    return bankingInformationService.getBankInfoList(transfereeId);
  },
);

const getBankInfoListFullfield = (state: any, action: any) => {
  state.bankInfoList.loading = false;
  state.bankInfoList.data = action.payload?.data.bankinfoList;

  state.view.error = null;
};
const getBankInfoListPending = (state: any, action: any) => {
  state.bankInfoList.loading = true;
  state.bankInfoList.error = action?.error?.message;
};
const getBankInfoListRejected = (state: any, action: any) => {
  state.bankInfoList.loading = false;
  state.bankInfoList.error = action?.error?.message;
};

//get bank info

export const getBankInfo = createAsyncThunk(
  "ACTION_GET_BANK_INFO",
  async (id: string) => {
    return bankingInformationService.getBankInfo(id);
  },
);

const getBankInfoFullfield = (state: any, action: any) => {
  state.bankInfo.loading = false;
  state.bankInfo.data = action?.payload?.data.bankinfo;
};
const getBankInfoPending = (state: any, action: any) => {
  state.bankInfo.loading = true;
  state.bankInfo.error = action?.error?.message;
};
const getBankInfoRejected = (state: any, action: any) => {
  state.bankInfo.loading = false;
  state.bankInfo.error = action?.error?.message;
};

//setDismissedForIds
export const setDismissedForIds = createAsyncThunk(
  "ACTION_SET_DISMISS_FOR_IDS",
  async (data: any) => {
    return bankingInformationService.setDismissedForIds(data);
  },
);

const setDismissedForIdsFullfield = (state: any, action: any) => {
  state.dismiss.loading = false;
  state.dismiss.data = action.payload?.data;
  state.dismiss.error = null;
};
const setDismissedForIdsPending = (state: any, action: any) => {
  state.dismiss.loading = true;
  state.dismiss.error = action?.error?.message;
};

const setDismissedForIdsRejected = (state: any, action: any) => {
  state.dismiss.loading = false;
  state.dismiss.error = action?.error?.message;
};

// ACTION_GET_BANKING_VIEW
export const getbankingView = createAsyncThunk(
  "ACTION_GET_BANKING_VIEW",
  async (id: string) => {
    return bankingInformationService.getBankingView(id);
  },
);
const getbankingViewFullfield = (state: any, action: any) => {
  state.view.loading = false;
  state.view.data = action.payload?.data;
  state.view.error = null;
};
const getbankingViewPending = (state: any, action: any) => {
  state.view.loading = true;
  state.view.error = action?.error?.message;
};
const getbankingViewRejected = (state: any, action: any) => {
  state.view.loading = false;
  state.view.error = action?.error?.message;
};

const bankingInformationSlice = createSlice({
  name: "bankingInformation",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // ACTION_GET_BANKING_ACCOUNTS
    builder.addCase(getbankingAccounts.fulfilled, getbankingAccountsFullfield);
    builder.addCase(getbankingAccounts.pending, getbankingAccountsPending);
    builder.addCase(getbankingAccounts.rejected, getbankingAccountsRejected);

    // ACTION_GET_BANKING_VIEW
    builder.addCase(getbankingView.fulfilled, getbankingViewFullfield);
    builder.addCase(getbankingView.pending, getbankingViewPending);
    builder.addCase(getbankingView.rejected, getbankingViewRejected);

    builder.addCase(
      getBankInfoUpdates.fulfilled,
      getBankInfoUpdatesFullfifield,
    );
    builder.addCase(getBankInfoUpdates.pending, getBankInfoUpdatesPending);
    builder.addCase(getBankInfoUpdates.rejected, getBankInfoUpdatesRejected);

    //getBankInfoList
    builder.addCase(getBankInfoList.fulfilled, getBankInfoListFullfield);
    builder.addCase(getBankInfoList.pending, getBankInfoListPending);
    builder.addCase(getBankInfoList.rejected, getBankInfoListRejected);

    //getBankInfo
    builder.addCase(getBankInfo.fulfilled, getBankInfoFullfield);
    builder.addCase(getBankInfo.pending, getBankInfoPending);
    builder.addCase(getBankInfo.rejected, getBankInfoRejected);

    //setDismissedForIds
    builder.addCase(setDismissedForIds.fulfilled, setDismissedForIdsFullfield);
    builder.addCase(setDismissedForIds.pending, setDismissedForIdsPending);
    builder.addCase(setDismissedForIds.rejected, setDismissedForIdsRejected);
  },
});

export default bankingInformationSlice.reducer;
