import axios from "..";
import { GET_FINANCIAL_VIEW } from "../../resources/constants";

class financialOverviewService {
  public static async getView(id: string) {
    return await axios.get(GET_FINANCIAL_VIEW + "/" + id);
  }
}

export default financialOverviewService;
