import React from "react";
import { Typography } from "antd";

interface Props {
  type?: "heading" | "title" | "paragraph" | "text";
  level?: 1 | 2 | 3 | 4 | 5;
  className?: string;
  children?: React.ReactNode;
  onClickEvent?: React.ReactEventHandler;
}

const CustomTypography: React.FC<Props> = ({
  type = "text",
  className,
  children,
  level = 1,
  onClickEvent = () => {},
}) => {
  const { Title, Paragraph, Text } = Typography;
  className = className ? className + " customTypo" : "customTypo";
  switch (type) {
    case "heading":
      return (
        <Title level={level} className={className} onClick={onClickEvent}>
          {children}
        </Title>
      );
    case "title":
      return (
        <Title level={level} className={className} onClick={onClickEvent}>
          {children}
        </Title>
      );
    case "paragraph":
      return (
        <Paragraph onClick={onClickEvent} className={className}>
          {children}
        </Paragraph>
      );
    default:
      return (
        <Text onClick={onClickEvent} className={className}>
          {children}
        </Text>
      );
  }
};

export default CustomTypography;
