import React from "react";
import { Row, Col, Button } from "antd";
import ImageComp from "../CustsomImage/ImageComp";
import CustomTypography from "../CustomTypoGraphy/CustomTypoGraphy";
import { labels } from "../../resources/labelstext";
import { backArrow } from "../../resources/SVGImages/dashboard";
import { useNavigate } from "react-router-dom";

interface ErrorCompProps {
  code?: string;
}
const imageObject = {
  "401": "/global/images/ErrorImages/error401.png",
  "404": "/global/images/ErrorImages/error404.png",
  "503": "/global/images/ErrorImages/error503.png",
  "403": "/global/images/ErrorImages/error403.png",
  maintenence: "/global/images/ErrorImages/errorMaintenance.png",
};

const ErrorComp: React.FC<ErrorCompProps> = ({ code }) => {
  const navigate = useNavigate();
  let renderedImage;
  let message;
  switch (code) {
    case "401":
      message = labels.error401;
      renderedImage = <ImageComp src={imageObject["401"]}></ImageComp>;
      break;
    case "403":
      message = labels.error403;
      renderedImage = <ImageComp src={imageObject["403"]}></ImageComp>;
      break;
    case "404":
      message = labels.error404;
      renderedImage = <ImageComp src={imageObject["404"]}></ImageComp>;
      break;
    case "503":
      message = labels.error503;
      renderedImage = <ImageComp src={imageObject["503"]}></ImageComp>;
      break;
    case "maintenence":
      message = labels.errorMaintenence;
      renderedImage = <ImageComp src={imageObject.maintenence}></ImageComp>;
      break;
    default:
      message = labels.error404;
      renderedImage = <ImageComp src={imageObject["404"]}></ImageComp>;
  }

  return (
    <Row gutter={[20, 20]}>
      <Col
        xs={{ span: 20, offset: 2 }}
        md={{ span: 12, offset: 6 }}
        className="errorContentImage"
      >
        {renderedImage}
      </Col>
      <Col xs={16} md={16} offset={4}>
        <CustomTypography className="errorContentMessage">
          {message}
        </CustomTypography>
      </Col>
      <Col
        xs={{ span: 4, offset: 6 }}
        md={{ span: 8, offset: 8 }}
        className="errorContentButton"
      >
        <Button
          onClick={() => {
            code === "401" ? navigate("/") : (window.location.href = "/");
          }}
          icon={backArrow}
        >
          {labels.gotoHome}
        </Button>
      </Col>
    </Row>
  );
};
export default ErrorComp;
