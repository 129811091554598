import axios from "..";
import {
  CREATE_EXPENSE_CATEGORY,
  CREATE_EXPENSE_TYPE,
  CREATE_SUPPLIER_URL,
  DELETE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_TYPE,
  DELETE_SUPPLIER,
  GET_EXPENSE_CATEGORY,
  GET_EXPENSE_CATEGORY_DETAILS,
  GET_EXPENSE_TYPE_DETAILS,
  GET_SUPPLIER_URL,
  MANAGE_EXPENSE_CATEGORY,
  UPDATE_EXPENSE_CATEGORY,
  UPDATE_EXPENSE_TYPE,
  UPDATE_SUPPLIER_URL,
} from "../../resources/constants";

export default class ExpenseCategoryServices {
  public static async getExpenseCategory() {
    try {
      const result: any = await axios.get(GET_EXPENSE_CATEGORY);
      return result?.data;
    } catch (error) {
      return error;
    }
  }

  public static async getExpenseType() {
    try {
      const result: any = await axios.get(MANAGE_EXPENSE_CATEGORY);
      return result;
    } catch (error) {
      return error;
    }
  }

  public static async createExpenseCategory(data: any) {
    if (data.id)
      return await axios.put(UPDATE_EXPENSE_CATEGORY + "/" + data.id, data);
    else {
      delete data.id;
      return await axios.post(CREATE_EXPENSE_CATEGORY, data);
    }
  }
  public static async createExpenseType(data: any) {
    if (data.id)
      return await axios.put(UPDATE_EXPENSE_TYPE + "/" + data.id, data);
    else {
      delete data.id;
      return await axios.post(CREATE_EXPENSE_TYPE, data);
    }
  }

  public static async deleteExpenseCategory(data: any) {
    return await axios.del(DELETE_EXPENSE_CATEGORY + "/" + data.id);
  }

  public static async deleteExpenseType(data: any) {
    return await axios.del(DELETE_EXPENSE_TYPE + "/" + data.id);
  }

  public static async getExpenseCategoryDetails(data: any) {
    return await axios.get(GET_EXPENSE_CATEGORY_DETAILS + "/" + data.id);
  }

  public static async getExpenseTypeDetails(data: any) {
    return await axios.get(GET_EXPENSE_TYPE_DETAILS + "/" + data.id);
  }
}
