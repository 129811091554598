import axios from "..";
import {
  GET_CLIENT_MEMBER_LISTING,
  GET_CLIENT_MEMBER_ROLES,
  GET_CLIENT_MEMBER_VIEW,
  CREATE_CLIENT_MEMBER,
  UPDATE_CLIENT_MEMBER,
} from "../../resources/constants";

class clientMemberService {
  public static async getListing(data: any) {
    return axios.get(
      GET_CLIENT_MEMBER_LISTING + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getView(id: string) {
    return axios.get(GET_CLIENT_MEMBER_VIEW + "/" + id);
  }

  public static async getRoles(data: any) {
    return axios.get(
      GET_CLIENT_MEMBER_ROLES + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async create(data: any) {
    return axios.put(CREATE_CLIENT_MEMBER, data);
  }

  public static async update(data: any) {
    return axios.post(UPDATE_CLIENT_MEMBER, data);
  }
}

export default clientMemberService;
