import React from "react";
import SVGIcon from "../components/SVGIcon/SVGIcon";
import {
  bellIcon,
  fileShareIcon,
  helpIcon,
  propertyIcon,
} from "../resources/SVGImages/dashboard";
import {
  dashboard as DashboardIcons,
  servicesMenuIcon,
  toolIcon,
  transferees,
  admin,
} from "../resources/SVGImages/dashboard";

const DashboardIcon = (
  <SVGIcon className="dashboardMenuIcon" childRen={DashboardIcons} />
);
const TransfereesIcon = (
  <SVGIcon className="dashboardMenuIcon" childRen={transferees} />
);

const serviceIcon = (
  <SVGIcon className="dashboardMenuIcon" childRen={servicesMenuIcon} />
);
const toolsIcon = <SVGIcon className="dashboardMenuIcon" childRen={toolIcon} />;

const adminIcon = <SVGIcon className="dashboardMenuIcon" childRen={admin} />;

const usersIcon = (
  <SVGIcon className="dashboardMenuIcon" childRen={transferees} />
);

export const getTransfereesNav = (
  policyType: string | null,
  userInfo: any,
): any => {
  return [
    {
      icon: DashboardIcon,
      key: "dashboard",
      label: "Dashboard",
      id: "dashboard",
    },

    ...getToolsAndServicesByPolicy(policyType, userInfo),
  ];
};
// used for transferee navs
export const getToolsAndServicesByPolicy = (
  policyType: string | null,
  userInfo: any,
): {
  icon: React.ReactNode;
  key: number | string;
  children?: { key: string; label: string; id?: string }[];
  label: string;
  id?: string;
}[] => {
  if (policyType === null) {
    return [
      {
        icon: toolsIcon,
        key: "tools",
        children: [
          { key: "file-share", label: "File Share", id: "fileShare" },
          ...(userInfo?.allowExpenses
            ? [
                {
                  key: "expense-submission",
                  label: "Expense Submission",
                  id: "expenseSubmission",
                },
              ]
            : []),
          ...(userInfo?.showBankInfo
            ? [
                {
                  key: "banking-accounts",
                  label: "Banking Information",
                  id: "bankingAccounts",
                },
              ]
            : []),
        ],
        label: "Tools",
        id: "tools",
      },
      {
        icon: serviceIcon,
        key: "Services",

        children: [
          { key: "temp-living", label: "Temp Living", id: "tempLiving" },
        ],
        label: "Services",
        id: "services",
      },
      // {
      //   icon: helpIcon,
      //   key: "help",
      //   label: "Help",
      //   id: "help",
      // },
    ];
  } else if (policyType === "SG_LUMP_SUM") {
    return [
      {
        icon: toolsIcon,
        key: "tools",
        children: [
          {
            key: "quick-move-budget",
            label: "Quick Move Budget",
            id: "quickMoveBudget",
          },
          { key: "file-share", label: "File Share", id: "fileShare" },
          {
            key: "detailed-move-budget",
            label: "Detailed Move Budget",
            id: "detailedMoveBudget",
          },
          ...(userInfo?.allowExpenses
            ? [
                {
                  key: "expense-submission",
                  label: "Request Lump Sum",
                  id: "expenseSubmission",
                },
              ]
            : []),
          ...(userInfo?.showBankInfo
            ? [
                {
                  key: "banking-accounts",
                  label: "Banking Information",
                  id: "bankingAccounts",
                },
              ]
            : []),
        ],
        label: "Tools",
        id: "tools",
      },
      {
        icon: serviceIcon,
        key: "Services",
        children: [
          { key: "move-help", label: "Move Help", id: "moveHelp" },
          {
            key: "financial-overview",
            label: "Financial Overview",
            id: "financialOverview",
          },
          {
            key: "track-expense",
            label: "Track Expenses",
            id: "trackExpenses",
          },
        ],
        label: "Services",
        id: "services",
      },
    ];
  } else if (policyType === "MANAGED_LUMP_SUM") {
    return [
      {
        icon: toolsIcon,
        key: "tools",
        children: [
          { key: "file-share", label: "File Share", id: "fileShare" },
          ...(userInfo?.allowExpenses
            ? [
                {
                  key: "expense-submission",
                  label: "Expense Submission",
                  id: "expenseSubmission",
                },
              ]
            : []),
          ...(userInfo?.showBankInfo
            ? [
                {
                  key: "banking-accounts",
                  label: "Banking Information",
                  id: "bankingAccounts",
                },
              ]
            : []),
        ],
        label: "Tools",
        id: "tools",
      },
      {
        icon: serviceIcon,
        key: "Services",
        id: "services",
        children: [
          { key: "temp-living", label: "Temp Living", id: "tempLiving" },
          {
            key: "financial-overview",
            label: "Financial Overview",
            id: "financialOverview",
          },
        ],
        label: "Services",
      },
      // {
      //   icon: helpIcon,
      //   key: "help",
      //   label: "Help",
      //   id: "help",
      // },
    ];
  }

  return [];
};

export const clientNavs: any = [
  {
    icon: DashboardIcon,
    key: "dashboard",
    label: "Dashboard",
    id: "dashboard",
  },
  {
    icon: serviceIcon,
    key: "Services",
    children: [
      { key: "cost-estimates", label: "Cost Estimates", id: "costEstimates" },
      { key: "temp-living", label: "Temp Living", id: "tempLiving" },
    ],
    label: "Services",
    id: "services",
  },
  {
    icon: toolsIcon,
    key: "tools",
    children: [
      { key: "reports", label: "Reports", id: "reports" },
      { key: "file-share", label: "File Share", id: "fileShare" },
      { key: "exceptions", label: "Exceptions", id: "exceptions" },
    ],
    label: "Tools",
    id: "tools",
  },
  {
    icon: TransfereesIcon,
    key: "transferees",
    label: "Transferees",
    id: "transferees",
  },
  {
    icon: helpIcon,
    key: "help",
    label: "Help",
    id: "help",
  },
];

export let adminNavs: any = [
  {
    icon: DashboardIcon,
    key: "dashboard",
    label: "Dashboard",
    id: "dashboard",
  },
  {
    icon: serviceIcon,
    key: "Services",
    children: [
      { key: "cost-estimates", label: "Cost Estimates", id: "costEstimates" },
      { key: "temp-living", label: "Temp Living", id: "tempLiving" },
      {
        key: "financial-overview",
        label: "Financial Overview",
        id: "financialOverview",
      },
    ],
    label: "Services",
  },
  {
    icon: toolsIcon,
    key: "tools",
    children: [
      {
        key: "expense-submission",
        label: "Expense Submission",
        id: "expenseSubmission",
      },
      { key: "reports", label: "Reports", id: "reports" },
      { key: "file-share", label: "File Share", id: "fileShare" },
      { key: "exceptions", label: "Exceptions", id: "exceptions" },
    ],
    label: "Tools",
  },
  {
    icon: usersIcon,
    key: "users",
    children: [
      { key: "staff", label: "Staff", id: "staff" },
      { key: "clients", label: "Clients", id: "clients" },
      { key: "transferees", label: "Transferees", id: "transferees" },
    ],
    label: "Users",
    id: "users",
  },
  {
    icon: adminIcon,
    key: "admin",
    children: [
      { key: "suppliers", label: "Suppliers", id: "suppliers" },
      { key: "vendors", label: "Vendors", id: "vendors" },
      { key: "property-listing", label: "Properties", id: "propertyListing" },
      { key: "expense-types", label: "Expense Types", id: "expenseTypes" },
      {
        key: "client-configuration",
        label: "Client Configuration",
        id: "clientConfiguration",
      },
      {
        key: "cost-estimator-config/home-finding",
        label: "Cost Estimator Config",
        id: "costEstimatorConfig",
      },
      {
        key: "news",
        label: "News",
        id: "news",
      },
    ],
    label: "Admin",
    id: "admin",
  },
];

export const consultantNavs: any = [
  {
    icon: DashboardIcon,
    key: "dashboard",
    label: "Dashboard",
    id: "dashboard",
  },
  {
    icon: serviceIcon,
    key: "Services",
    children: [
      { key: "cost-estimates", label: "Cost Estimates", id: "costEstimates" },
      { key: "temp-living", label: "Temp Living", id: "tempLiving" },
      {
        key: "financial-overview",
        label: "Financial Overview",
        id: "financialOverview",
      },
    ],
    label: "Services",
    id: "services",
  },
  {
    icon: toolsIcon,
    key: "tools",
    children: [
      {
        key: "expense-submission",
        label: "Expense Submission",
        id: "expenseSubmission",
      },
      { key: "reports", label: "Reports", id: "reports" },
      { key: "hhg", label: "HHG Estimates", id: "hhg" },
      { key: "file-share", label: "File Share", id: "fileShare" },
      { key: "exceptions", label: "Exceptions", id: "exceptions" },
    ],
    label: "Tools",
    id: "tools",
  },
  {
    icon: usersIcon,
    key: "users",
    children: [
      { key: "clients", label: "Clients", id: "clients" },
      { key: "transferees", label: "Transferees", id: "transferees" },
    ],
    label: "Users",
    id: "users",
  },
];

export let supplierNavs: any = [
  {
    icon: DashboardIcon,
    key: "dashboard",
    label: "Dashboard",
    id: "dashboard",
  },
  {
    icon: bellIcon,
    key: "temp-living",
    label: "Requests",
    id: "tempLiving",
  },
  {
    icon: propertyIcon,
    key: "property-listing",
    label: "Properties",
    id: "propertyListing",
  },
  {
    icon: fileShareIcon,
    key: "file-share",
    label: "Files",
    id: "files",
  },
  // {
  //   icon: helpIcon,
  //   key: "help",
  //   label: "Help",
  //   id: "help",
  // },
];
