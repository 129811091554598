import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";

const LoginPage = lazy(() => import("../Login/LoginPage"));
const PageNotFound = lazy(() => import("../PageNotFound/PageNotFound"));
const FirstTimeUser = lazy(() => import("../FirstTimeUser/FirstTimeUser"));
const ForgotPassword = lazy(
  () => import("../ForgotPassWord/ForgotPasswordPage"),
);
const ForgotUserNamePage = lazy(
  () => import("../ForgotUserName/ForgotUserNamePage"),
);
const Unauthorized = lazy(() => import("../Unauthorized/Unauthorized"));
const FirstTimeLogin = lazy(() => import("../FirstTimeLogin/FirstTimeLogin"));

export default function AnonymousRoutes() {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/server-error" element={<PageNotFound />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path={"/first-time-user"} element={<FirstTimeUser />} />
        <Route path={"/first-time-login"} element={<FirstTimeLogin />} />
        <Route path={"/forgot-password"} element={<ForgotPassword />} />
        <Route path={"/forgot-user"} element={<ForgotUserNamePage />} />
      </Routes>
    </Suspense>
  );
}
