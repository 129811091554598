import axios from "..";
import {
  CREATE_CUSTOM_NOTIFICATION,
  GET_CM_GROUP_BY_CLIENT,
  GET_CUSTOM_NOTIFICATION,
  GET_CUSTOM_NOTIFICATION_LIST,
  GET_POLICY_BY_CLIENT,
  TOGGLE_CUSTOM_NOTIFICATION,
  UPDATE_APPROVAL,
  UPDATE_CUSTOM_NOTIFICATION,
} from "../../resources/constants";

class notificationService {
  public static async getCustomNotification(id: any) {
    return await axios.get(GET_CUSTOM_NOTIFICATION + "/" + id);
  }

  public static async getCustomNotificationList(data: any) {
    return await axios.get(
      GET_CUSTOM_NOTIFICATION_LIST +
        "/?" +
        new URLSearchParams(data).toString(),
    );
  }

  public static async getCMGroupFromClient(data: any) {
    return await axios.post(GET_CM_GROUP_BY_CLIENT, data);
  }

  public static async getPolicyFromClient(data: any) {
    return await axios.post(GET_POLICY_BY_CLIENT, data);
  }

  public static async createCustomNotification(data: any) {
    return await axios.put(CREATE_CUSTOM_NOTIFICATION, data);
  }

  public static async updateCustomNotification(data: any) {
    return await axios.post(UPDATE_CUSTOM_NOTIFICATION, data);
  }

  public static async toggleCustomNotification(data: any) {
    return await axios.post(TOGGLE_CUSTOM_NOTIFICATION, data);
  }
}

export default notificationService;
