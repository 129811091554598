import {
  GET_ADHOC_TEMPLATE,
  GET_ADHOC,
  ADHOC_EXPORT_ASSIGNMENT,
  UPDATE_ADHOC,
} from "../../resources/constants";
import axios from "..";

export default class AdHocService {
  public static async getAdHocTemplate() {
    return await axios.get(GET_ADHOC_TEMPLATE);
  }

  public static async getAdHocPolicy(id: string | number) {
    return await axios.get(GET_ADHOC + "/" + id);
  }

  public static async updateAdhoc(data: any) {
    return await axios.post(UPDATE_ADHOC, data);
  }

  public static async exportPDF(data: any) {
    const headers = {
      responseType: "blob",
    };
    return axios.post<any>(ADHOC_EXPORT_ASSIGNMENT, data, headers);
  }
}
