import axios from "..";
import {
  GET_ALL_POLICY_LIST,
  GET_POLICY_VIEW,
  CREATE_POLICY,
  UPDATE_POLICY,
  DELETE_POLICY,
  GET_POLICY_CONFIG,
  CREATE_POLICY_CONFIG,
  UPDATE_POLICY_CONFIG,
  GET_POLICY_REM_LIST,
  DELETE_POLICY_CATEGORY_CONFIG,
  UPDATE_POLICY_CATEGORY_CONFIG,
  CREATE_POLICY_CATEGORY_CONFIG,
  GET_POLICY_CATEGORY_CONFIG,
  GET_POLICY_LIMIT_GROUP,
  CREATE_POLICY_LIMIT_GROUP,
  UPDATE_POLICY_LIMIT_GROUP,
  DELETE_POLICY_LIMIT_GROUP,
  COPY_POLICY,
  POLICY_CHANGE_LOG,
} from "../../resources/constants";

class policyService {
  public static async getListing(data: any) {
    return axios.get(
      GET_ALL_POLICY_LIST + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async getView(id: string) {
    return axios.get(GET_POLICY_VIEW + "/" + id);
  }

  public static async getConfig(id: string) {
    return axios.get(GET_POLICY_CONFIG + "/?policyId=" + id);
  }

  public static async getREMList(id: string) {
    return axios.get(GET_POLICY_REM_LIST + "/" + id);
  }

  public static async copyPolicy(id: string) {
    return axios.post(COPY_POLICY + "/?policyId=" + id, {});
  }

  public static async getPolicyLogs(data: any) {
    return await axios.get(
      POLICY_CHANGE_LOG + "/?" + new URLSearchParams(data).toString(),
    );
  }

  public static async createConfig(data: any) {
    return axios.post(CREATE_POLICY_CONFIG, data);
  }

  public static async updateConfig(data: any) {
    return axios.put(UPDATE_POLICY_CONFIG + "/" + data.generalConfig.id, data);
  }

  public static async deletePolicy(id: string) {
    return axios.del(DELETE_POLICY + "/" + id);
  }

  public static async createPolicy(data: any) {
    return axios.post(CREATE_POLICY, data);
  }

  public static async getCategoryConfig(id: string) {
    return axios.get(GET_POLICY_CATEGORY_CONFIG + "/" + id);
  }

  public static async updatePolicy(data: any) {
    return axios.put(UPDATE_POLICY + "/" + data?.id, data);
  }

  public static async createCategoryConfig(data: any) {
    return axios.post(CREATE_POLICY_CATEGORY_CONFIG, data);
  }

  public static async updateCategoryConfig(data: any) {
    return axios.put(UPDATE_POLICY_CATEGORY_CONFIG + "/" + data?.id, data);
  }

  public static async deleteCategoryConfig(id: string) {
    return axios.del(DELETE_POLICY_CATEGORY_CONFIG + "/" + id);
  }

  public static async getLimitGroup(id: string) {
    return axios.get(GET_POLICY_LIMIT_GROUP + "/" + id);
  }

  public static async createLimitGroup(data: any) {
    return axios.post(CREATE_POLICY_LIMIT_GROUP, data);
  }

  public static async updateLimitGroup(data: any) {
    return axios.put(UPDATE_POLICY_LIMIT_GROUP + "/" + data?.id, data);
  }

  public static async deleteLimitGroup(id: string) {
    return axios.del(DELETE_POLICY_LIMIT_GROUP + "/" + id);
  }
}

export default policyService;
