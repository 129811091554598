import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import selfGuidedService from "../../services/SelfGuided/SelfGuided";

export const initialState: any = {
  budget: {
    error: null,
    loading: true,
    data: {},
  },
  list: {
    error: null,
    loading: true,
    data: {},
  },
};

// ***********************************
export const getBudget = createAsyncThunk(
  "ACTION_GET_BUDGET",
  async (id: string) => {
    return selfGuidedService.getBudget(id);
  },
);

const getBudgetFullfield = (state: any, action: any) => {
  state.budget.loading = false;
  state.budget.data = action.payload?.data;
  state.budget.error = null;
};
const getBudgetPending = (state: any, action: any) => {
  state.budget.loading = true;
  state.budget.data = {};
  state.budget.error = action?.error?.message;
};
const getBudgetRejected = (state: any, action: any) => {
  state.budget.loading = false;
  state.budget.data = {};
  state.budget.error = action?.error?.message;
};

// ***********************************
export const getSGList = createAsyncThunk(
  "ACTION_GET_SG_LIST",
  async (id: string) => {
    return selfGuidedService.getSGList(id);
  },
);

const getSGListFullfield = (state: any, action: any) => {
  state.list.loading = false;
  state.list.data = action.payload?.data;
  state.list.error = null;
};
const getSGListPending = (state: any, action: any) => {
  state.list.loading = true;
  state.list.data = {};
  state.list.error = action?.error?.message;
};
const getSGListRejected = (state: any, action: any) => {
  state.list.loading = false;
  state.list.data = {};
  state.list.error = action?.error?.message;
};

const selfGuidedSlice = createSlice({
  name: "SelfGuided",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    // ***********************************
    builder.addCase(getBudget.fulfilled, getBudgetFullfield);
    builder.addCase(getBudget.pending, getBudgetPending);
    builder.addCase(getBudget.rejected, getBudgetRejected);

    // ***********************************
    builder.addCase(getSGList.fulfilled, getSGListFullfield);
    builder.addCase(getSGList.pending, getSGListPending);
    builder.addCase(getSGList.rejected, getSGListRejected);
  },
});

export default selfGuidedSlice.reducer;
export const { resetState } = selfGuidedSlice.actions;
