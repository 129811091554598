import axios from "../..";
import {
  CREATE_COST_ESTIMATE_CONFIG,
  DELETE_COST_ESTIMATE_CONFIG,
  GET_COST_ESTIMATE_CONFIG_BY_ID,
  GET_COST_ESTIMATE_CONFIGS,
  GET_COST_ESTIMATES_URL,
  UPDATE_COST_ESTIMATE_CONFIG,
} from "../../../resources/constants";

interface getCostEstimatesConfigParams extends Record<string, any> {
  page: number;
  limit: number;
  costEstimatorType: string;
  order: string;
  search: string;
  client?: string;
}

class costEstimatesConfigService {
  public static async getCostEstimateConfigs(
    params: getCostEstimatesConfigParams,
  ) {
    return await axios.get(
      GET_COST_ESTIMATE_CONFIGS + "/?" + new URLSearchParams(params).toString(),
    );
  }

  public static async getCostEstimateConfigById(costEstimatorId: string) {
    return await axios.get(
      GET_COST_ESTIMATE_CONFIG_BY_ID + "/" + costEstimatorId,
    );
  }

  public static async deleteCostEstimateConfigById(costEstimatorId: string) {
    return await axios.del(DELETE_COST_ESTIMATE_CONFIG + "/" + costEstimatorId);
  }

  public static async createCostEstimateConfig(data: any) {
    if (data.defaultConfig.id)
      return await axios.put(UPDATE_COST_ESTIMATE_CONFIG, data);
    else return await axios.post(CREATE_COST_ESTIMATE_CONFIG, data);
  }
}

export default costEstimatesConfigService;
