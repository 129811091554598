import React from "react";
import { Dropdown, Menu } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { dropDownArrow } from "../../resources/SVGImages/dashboard";
import "../../styles/components/DropdownWithLabel/DropdownWithLabel.scss";

interface DropdownProps {
  options: { key: string; label: string; icon?: React.ReactNode }[] | [];
  triggerText?: string;
  className?: string;
  icon?: React.ReactNode;
  onClick?: (e: any) => void;
  triggerIcon?: boolean;
  id?: string;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  options = [],
  triggerText,
  className,
  icon,
  onClick,
  triggerIcon,
  id,
}) => {
  const menu = (
    <Menu>
      {options.map((option) => (
        <Menu.Item
          className="dropDownOption"
          icon={option.icon}
          key={option.key}
          onClick={onClick}
        >
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const dropDownIcon = triggerText ? (
    triggerIcon ? (
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <ExportOutlined /> {triggerText}
      </a>
    ) : (
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {triggerText + " "}
        {dropDownArrow}
      </a>
    )
  ) : (
    icon
  );

  return (
    <Dropdown trigger={["click"]} overlay={menu} placement="bottomRight">
      {dropDownIcon}
    </Dropdown>
  );
};

export default DropdownComponent;
