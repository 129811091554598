export const labels = {
  passwordRequiredLabel: "Password must have",
  userNameLabel: "Username",
  passwordLabel: "Password",
  verificationCodeLabel: "Verification Code",
  confirmPasswordLabel: "Confirm New Password",
  newPasswordLabel: "New Password",

  userName: "Username",
  forgotUsername: "Forgot Your Username?",
  email: "Email",
  desCountry: "Choose Destination Country",
  desState: "Choose Destination State",
  notifications: "Choose Toast",
  policy: "Choose Policy",
  Client: "Choose Client",
  status: "Choose Status",
  consultant: "Choose Consultant",
  service: "Choose Service",
  error503:
    "This page is temporarily unavailable. We are working to resolve the issue as quickly as possible. Thank you for your patience.",
  error404:
    "Looks like you took a wrong turn. The page you requested is not available. Please try again later or contact our support team for assistance.",
  error401:
    "We're sorry, but you do not have the necessary permissions to access this content. Please contact support for assistance.",
  errorMaintenence:
    "We apologize for the inconvenience, it seems like something went wrong. Please try again later or contact our support team for assistance.",
  error403:
    "Forbidden Access: We're sorry, but you do not have the necessary permissions to access this content. Please contact support for assistance.",
  unknownError: "unknown Error",
  gotoHome: "Go Back To Homepage",
  upcomingEvents: "Upcoming Events This Week",
  Scheduled: "Scheduled",
  completedEvents: "Completed Events",
  relocationDetails: "Relocation Details",
  internationalMove: "International Move",
  relocation: "Relocation",
  businessUnit: "Business Unit",
  moveManagement: "Move Management",
  tempAccomodation: "Temporary Accommodations",
  setAndOnGoSupport: "Settling-In and Ongoing Support",
  permAccom: "Permanent Accommodations",
  saveAndContinue: "Save & Continue",
  addNewEmployee: "Add New Employee",
  profileDetails: "Profile Details",
  divisionAndPolicy: "Division & Policy",
  addNewEmployeeLabels: {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phoneNumber: "Phone Number",
    jobTitle: "Job Title",
    division: "Division",
    policy: "Policy",
    grossUpRate: "Gross Up Rate",
  },
  originDetails: "Origin Details",
  destinationDetails: "Destination Details",
  otherDetails: "Other Details",
  approveSend: "Approve & Send",
  divisionDetails: "Division Details",
  policyDetails: "Policy Details",
  taxScenario: "Tax Scenario",
  enableGrossUp: "Enable Gross Up",
  approveInvitation: "Approve Invitation",
  transfereeRecords: "Transferees On Record",
  contactRecords: "Contacts On Record",
  chooseStatus: "Choose Status",
  choosePolicy: "Choose Policy",
  chooseConsultant: "Choose Consultant",
};
