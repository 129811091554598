import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationService from "../../services/Notification/Notification";

export const initialState = {
  list: {
    loading: false,
    data: {},
    error: null,
  },
  vars: {
    page: 1,
    limit: 10,
    mine: false,
    query: "",
  },
};

export const getCustomNotificationList = createAsyncThunk(
  "ACTION_CUSTOM_NOTIFICATION_LISTING",
  async (data: any) => {
    return notificationService.getCustomNotificationList(data);
  },
);

const getListingFullfield = (state: any, action: any) => {
  state.list.loading = false;
  state.list.data = action.payload?.data;
  state.list.error = null;
};
const getListingPending = (state: any, action: any) => {
  state.list.loading = true;
  state.list.data = {};
  state.list.error = action.error ? action?.error?.message : null;
};
const getListingRejected = (state: any, action: any) => {
  state.list.loading = false;
  state.list.data = {};
  state.list.error = action.error ? action?.error?.message : "Error";
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateVars(state, action) {
      state.vars = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getCustomNotificationList.fulfilled, getListingFullfield);
    builder.addCase(getCustomNotificationList.pending, getListingPending);
    builder.addCase(getCustomNotificationList.rejected, getListingRejected);
  },
});

export default notificationSlice.reducer;
export const { updateVars, resetState } = notificationSlice.actions;
