import React, { FC } from "react";

interface IconProps {
  childRen?: React.ReactNode;
  className?: string;
}

const SVGIcon: FC<IconProps> = ({ childRen, className }) => {
  return <span className={className}>{childRen}</span>;
};

export default SVGIcon;
