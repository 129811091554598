import {
  DELETE_TRANSFEREE,
  GET_TRANSFEREE_HISTORY_URL,
  GET_TRANSFEREE_LIST_URL,
  UPDATE_TRANSFEREE_STATUS,
  countriesUrl,
  SET_PII_ACKNOWLEDGEMENT,
  GET_ALL_TRANSFEREES,
} from "../resources/constants";
import axios from ".";

export interface loginPageResponse {
  data?: object;
  success?: boolean;
  message?: string;
}

interface TransfereeDetail {
  id: string;
  transfereeId: string;
  divFieldMappingId: string;
  value: string;
}

interface Transferee {
  id: string;
  firstName: string;
  lastName: string;
  miloId: string;
  policyId: string | null;
  policy: string | null;
  transfereeDetails: TransfereeDetail[];
  consultant: Consultant;
}

interface Consultant {
  firstName: string;
  lastName: string;
}

interface TransfereesData {
  data: {
    transferees: Transferee[];
    totalCount: number;
  };
  success: boolean;
  message: string;
}

export default class transfereeServices {
  static async getCountry() {
    const result = await axios.get(countriesUrl);
    return result;
  }

  static async getTransferees(body: any) {
    const result: TransfereesData = await axios.post(
      GET_TRANSFEREE_LIST_URL,
      body,
    );

    return result.data;
  }

  public static async getTransfereeHistory(payLoad: any) {
    const result: any = await axios.get(
      `${GET_TRANSFEREE_HISTORY_URL}?transfereeId=${payLoad?.id}&limit=${payLoad?.limit}&page=${payLoad?.page}`,
    );

    return result?.data;
  }

  public static async getCountries() {
    return await transfereeServices.getCountry();
  }

  public static async getAllTransferees(body: any) {
    return await transfereeServices.getTransferees(body);
  }

  public static async updateTransfereeStatus(data: any) {
    return axios.post(UPDATE_TRANSFEREE_STATUS, data);
  }

  public static async setPIIAcknowledgement(data: any) {
    return axios.post(SET_PII_ACKNOWLEDGEMENT, data);
  }

  public static async deleteTransferee(id: any) {
    return axios.del(DELETE_TRANSFEREE + "/" + id);
  }

  public static async fetchAllTransferees() {
    return axios.get(GET_ALL_TRANSFEREES);
  }
}
