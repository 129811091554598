export const ACTION_GET_DIVISION_VIEW = "ACTION_GET_DIVISION_VIEW";
export const ACTION_GET_POLICY_LIST = "ACTION_GET_POLICY_LIST";
export const ACTION_GET_CONSULTANT_LIST = "ACTION_GET_CONSULTANT_LIST";
export const ACTION_GET_DIVISION_FORM = "ACTION_GET_DIVISION_FORM";
export const ACTION_GET_INIT_CONFIG = "ACTION_GET_INIT_CONFIG";
export const ACTION_DIVISION_LISTING = "ACTION_DIVISION_LISTING";

export const ACTION_AUTHORIZED_DIVISION_LISTING =
  "ACTION_AUTHORIZED_DIVISION_LISTING";
export const ACTION_DIVISION_CREATION = "ACTION_DIVISION_CREATION";
export const ACTION_COST_ESTIMATES_LISTING = "ACTION_COST_ESTIMATES_LISTING";
export const ACTION_GET_USER_INFO = "ACTION_GET_USER_INFO";
export const ACTION_LOGIN = "ACTION_LOGIN";
export const ACTION_FORGOT_PASSWORD = "ACTION_FORGOT_PASSWORD";
export const ACTION_FORGOT_USERNAME = "ACTION_FORGOT_USERNAME";
export const ACTION_SIGNUP = "ACTION_SIGNUP";
export const ACTION_FILTERS = "ACTION_FILTERS";
export const ACTION_COST_ESTIMATES_VIEW = "ACTION_COST_ESTIMATES_VIEW";
export const ACTION_GET_COUNTRY = "ACTION_GET_COUNTRY";
export const ACTION_GET_STATE = "ACTION_GET_STATE";
export const ACTION_GET_CITY = "ACTION_GET_CITY";
export const ACTION_GET_COSTESTIMATE_STATE = "COSTESTIMATE/" + ACTION_GET_STATE;
export const ACTION_GET_COSTESTIMATE_ORIGIN_CITY =
  "COSTESTIMATE_ORIGIN/" + ACTION_GET_CITY;
export const ACTION_GET_COSTESTIMATE_DESTINATION_CITY =
  "COSTESTIMATE_DESTINATION/" + ACTION_GET_CITY;
export const ACTION_GET_SHIPPING_TOTAL = "ACTION_GET_SHIPPING_TOTAL";
export const ACTION_GET_COSTESTIMATE_DEFAULT_CONFIG =
  "ACTION_GET_COSTESTIMATE_DEFAULT_CONFIG";
export const ACTION_GET_COSTESTIMATE_APPROVERS =
  "ACTION_GET_COSTESTIMATE_APPROVERS";

export const ACTION_GET_TEMP_LIVING_TRANSFEREES =
  "ACTION_GET_TEMP_LIVING_TRANSFEREES";
export const ACTION_GET_TEMP_LIVING_OPTIONS = "ACTION_GET_TEMP_LIVING_OPTIONS";

export const ACTION_COST_ESTIMATES_CONFIGS_LISTING =
  "ACTION_COST_ESTIMATES_CONFIGS_LISTING";

export const ACTION_COST_ESTIMATES_CONFIG_BY_ID =
  "ACTION_COST_ESTIMATES_CONFIG_BY_ID";
