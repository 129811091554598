import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  fileShareListing: {
    currentPage: 1,
    search: "",
    owner: false,
  },
  fileTransferee: {
    currentPage: 1,
    search: "",
    status: "",
  },
  bankingAccounts: {
    currentPage: 1,
  },
  costEstimateListing: {
    currentPage: 1,
    search: "",
    showDropdowns: false,
    client: undefined,
  },
  tempLivingListing: {
    currentPage: 1,
    search: "",
    showFilters: false,
    clientId: null,
    consultantId: null,
    tloStatus: null,
    trlStatus: null,
    transfereeStatus: null,
  },
  exceptionListing: {
    currentPage: 1,
  },
  adminTransfereeListing: {
    page: 1,
    limit: 10,
    viewMine: false,
    filters: {
      policyId: null,
      consultantId: null,
      clientId: null,
      authStartDate: null,
      authEndDate: null,
      query: "",
    },
  },
  adminTransfereeListingSearch: "",
};

//create a slice for Client
const GlobalVars = createSlice({
  name: "globalVars",
  initialState,
  reducers: {
    setGlobalVars: (state: any, action: any) => {
      return action.payload;
    },
  },
});

export default GlobalVars.reducer;
export const { setGlobalVars } = GlobalVars.actions;
