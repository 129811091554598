import axios from "..";
import {
  GET_EXPENSE_SUBMISSION_LISTING,
  GET_EXPENSE_SUBMISSION_FILTERS,
  GET_EXPENSE_SUBMISSION_BY_ID,
  GET_EXPENSE_SUBMISSION_FOLDER_LIST,
  CREATE_EXPENSE_SUBMISSION_FOLDER,
  UPDATE_EXPENSE_SUBMISSION_FOLDER,
  GET_EXPENSE_SUBMISSION_CATEGORY,
  GET_EXPENSE_SUBMISSION_TYPE,
  CREATE_EXPENSE_SUBMISSION,
  UPDATE_EXPENSE_SUBMISSION,
  UPLOAD_EXPENSE_SUBMISSION_FILE,
  DELETE_EXPENSE_SUBMISSION,
  DELETE_EXPENSE_SUBMISSION_FILE,
  GET_BENEFIT_LIMITS,
  SEND_EXPENSE_EMAIL,
  UPDATE_EXPENSE_PROCESS,
  ASSIGN_EXPENSE,
  GET_EXPENSE_HISTORY,
} from "../../resources/constants";

class expenseSubmissionService {
  public static async getListing(filters: any, params: any) {
    return await axios.post(
      GET_EXPENSE_SUBMISSION_LISTING +
        "/?" +
        new URLSearchParams(params).toString(),
      filters,
    );
  }

  public static async getFilters() {
    return await axios.get(GET_EXPENSE_SUBMISSION_FILTERS);
  }

  public static async getView(id: string) {
    return await axios.get(GET_EXPENSE_SUBMISSION_BY_ID + "/" + id);
  }

  public static async getFolders(id: string) {
    return await axios.get(
      GET_EXPENSE_SUBMISSION_FOLDER_LIST + "?transfereeId=" + id,
    );
  }

  public static async updateFolders(data: any) {
    return await axios.post(UPDATE_EXPENSE_SUBMISSION_FOLDER, data);
  }

  public static async createFolders(data: any) {
    return await axios.put(CREATE_EXPENSE_SUBMISSION_FOLDER, data);
  }

  public static async deleteExpense(id: string) {
    return await axios.del(DELETE_EXPENSE_SUBMISSION + "/" + id);
  }

  public static async deleteExpenseFile(id: string, name: string) {
    return await axios.del(
      DELETE_EXPENSE_SUBMISSION_FILE + "/" + id + "?fileName=" + name,
    );
  }

  public static async getCategory(data: any) {
    return await axios.get(
      GET_EXPENSE_SUBMISSION_CATEGORY +
        "/?" +
        new URLSearchParams(data).toString(),
    );
  }

  public static async getType(data: any) {
    return await axios.get(
      GET_EXPENSE_SUBMISSION_TYPE +
        "/" +
        data.id +
        "/?" +
        new URLSearchParams(data).toString(),
    );
  }

  public static async sendMail(id: string) {
    return await axios.get(SEND_EXPENSE_EMAIL + "/" + id);
  }

  public static async createExpense(data: any) {
    return await axios.post(CREATE_EXPENSE_SUBMISSION, data);
  }

  public static async updateIntExpense() {
    return await axios.post(
      UPDATE_EXPENSE_PROCESS + "/?international=true",
      {},
    );
  }

  public static async updateUSDExpense() {
    return await axios.post(
      UPDATE_EXPENSE_PROCESS + "/?international=false",
      {},
    );
  }

  public static async getBenefitLimits(data: any) {
    return await axios.post(GET_BENEFIT_LIMITS, data);
  }

  public static async assignExpesne(data: any) {
    return await axios.post(
      ASSIGN_EXPENSE + "/?" + new URLSearchParams(data).toString(),
      {},
    );
  }

  public static async updateExpense(data: any) {
    return await axios.put(UPDATE_EXPENSE_SUBMISSION + "/" + data.id, data);
  }

  public static async uploadFiles(id: string | number, data: any) {
    return await axios.post(UPLOAD_EXPENSE_SUBMISSION_FILE + "/" + id, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public static async uploadFilesosr(id: string | number, data: any) {
    return await axios.post(
      UPLOAD_EXPENSE_SUBMISSION_FILE + "/" + id + "?osrFile=true",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
  }

  public static async deleteExpenseFileosr(id: string | number, name: string) {
    return await axios.del(
      DELETE_EXPENSE_SUBMISSION_FILE +
        "/" +
        id +
        "?fileName=" +
        name +
        "&osrFile=true",
    );
  }

  public static async getExpenseHistory(id: string | number, data: any) {
    return await axios.get(
      GET_EXPENSE_HISTORY +
        "/" +
        id +
        "?" +
        new URLSearchParams(data).toString(),
    );
  }
}

export default expenseSubmissionService;
