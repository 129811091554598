import axios from "..";
import {
  GET_DIVISIONS_URL,
  GET_DIVISION_VIEW,
  GET_POLICY_LIST,
  GET_DIVISION_FORM,
  GET_INIT_CONFIG,
  CREATE_DIVISION,
  UPDATE_DIVISION,
  GET_AUTHORIZED_DIVISIONS_URL,
  GET_CONSULTANT_LIST,
  GET_TOP_DIVISION,
  UPDATE_TOP_DIVISION,
  GET_TOP_DIVISION_FORM,
  GET_AVAIL_POLICY,
  UPDATE_ASSIGN_POLICY,
  DIVISION_DELETE,
  DIVISION_TOGGLE,
} from "../../resources/constants";

export default class divisionsServices {
  static async getDivisions(
    clientId: string,
    divisionId: string,
    direction: string,
  ) {
    try {
      const result: any = await axios.get(
        `${GET_DIVISIONS_URL}?clientId=${clientId}${
          divisionId ? `&divisionId=${divisionId}` : ""
        }${direction ? `&direction=${direction}` : ""}`,
      );
      return result?.data?.divisionsList || [];
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getAllDivisions(
    clientId: string,
    divisionId: string,
    direction: string,
  ) {
    try {
      return await divisionsServices.getDivisions(
        clientId,
        divisionId,
        direction,
      );
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  static async getDivisionsAndId(
    clientId: string,
    divisionId: string,
    direction: string,
  ) {
    try {
      const result: any = await axios.get(
        `${GET_DIVISIONS_URL}?clientId=${clientId}${
          divisionId ? `&divisionId=${divisionId}` : ""
        }${direction ? `&direction=${direction}` : ""}`,
      );
      return { id: divisionId, data: result?.data?.divisionsList || [] };
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  static async getAuthorizedDivisions(payload: any) {
    try {
      const result: any = await axios.get(
        GET_AUTHORIZED_DIVISIONS_URL + "?" + new URLSearchParams(payload),
      );
      return { id: "", data: result?.data?.authorizedDivisions || [] };
    } catch (error: any) {
      throw new Error(error?.message);
    }
  }

  public static async getPolicyList(data: any) {
    return await axios.get(GET_POLICY_LIST + "/?" + new URLSearchParams(data));
  }

  public static async getConsultantList() {
    return await axios.get(GET_CONSULTANT_LIST);
  }

  public static async getDivisionView(data: any) {
    return await axios.get(
      GET_DIVISION_VIEW + "/?" + new URLSearchParams(data),
    );
  }

  public static async getDivisionForm(data: any) {
    return await axios.get(
      GET_DIVISION_FORM + "/?" + new URLSearchParams(data),
    );
  }

  public static async getInitConfig(data: any) {
    return await axios.get(GET_INIT_CONFIG + "/?" + new URLSearchParams(data));
  }

  public static async getTopDivisionForm(data: any) {
    return await axios.get(
      GET_TOP_DIVISION_FORM + "/?" + new URLSearchParams(data),
    );
  }

  public static async createDivision(data: any) {
    return await axios.put(CREATE_DIVISION, data);
  }

  public static async updateDivision(data: any) {
    return await axios.post(UPDATE_DIVISION, data);
  }

  public static async updateTopDivision(data: any) {
    return await axios.post(UPDATE_TOP_DIVISION, data);
  }

  public static async getTopDivision(data: any) {
    return await axios.get(GET_TOP_DIVISION + "/?" + new URLSearchParams(data));
  }

  public static async getAvailPolicy(data: any) {
    return await axios.get(GET_AVAIL_POLICY + "/?" + new URLSearchParams(data));
  }

  public static async updateAssignPolicy(data: any) {
    return await axios.post(UPDATE_ASSIGN_POLICY, data);
  }

  public static async deleteDivision(id: any) {
    return await axios.del(DIVISION_DELETE + "/" + id);
  }

  public static async toggleDivision(id: any) {
    return await axios.put(DIVISION_TOGGLE + "/" + id, {});
  }
}
