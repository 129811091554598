export const constantsText = {
  reloPortal: "ReloPortal",
  LoginHeading: "Log In to",
  submit: "Login",
  state: "State",
  firstTimeSubmit: "Submit",
  forgotPassword: "Forgot My Password",
  googleName: "Google",
  whatsNew: "What’s new?",
  copyWright: `© ${new Date().getFullYear()} Corporate Relocation International`,
  createNewAccount: "First Time User?",
  backToLogin: "Back to Login",
  authorizeRelocationBtnText: "Authorize Relocation",
  submitExpesnses: "Submit Expenses",
  createYourPassword: "Create Your Password",
  forgotPasswordHeading: "Forgot Password?",
  forgotUserName: "Forgot Username?",
  programAtGlance: "Program at a Glance",
  authByCountry: "Active Files - YTD by Country",
  ytdTotalSpends: "YTD Total Spend",
  ytdTotalAuths: "YTD Total Authorizations",
  authPolicy: "Authorizations by Policy",
  spendByPolicy: "Spend by Policy",
  exceptionsByPolicy: "Exceptions by Policy",
  drawerFilter: "Filters",
  humanResources: "Human Resources",
  salesAndMarketting: "Sales and Marketing",
  operations: "Operations",
  customerService: "Customer Service",
  rnd: "Research and Development",
  anF: "Accounting and Finance",
  divisionUnit: "Divisions",
  saveFavourite: "Save Favorite",
  startDate: "Financial Date",
  authDate: "Authorization Date",
  waypoint: "Waypoint",
  years: "Years",
  service: "Service",
  policy: "Policy",
  Client: "Client",

  applyFilters: "Apply Filters",
  setAsDefault: "Set as Default",
  reset: "Reset",
  upcomingVisaExp: "Upcoming Visa Expirations",
  quickLinks: "Quick Links",
  expenseByCategory: "Expense by Category",
  expenseByDetails: "Expense by Details",
  exceptionReport: "Exception Report",
  authReport: "Authorization Report",
  closedFileReport: "Closed File Report",
  favorites: "Favorites",
  Reports: "Reports",
  empProfs: "Employee Profiles",
  viewALl: "View All",
  fileShare: "File Share",
  edit: "Edit",
  delete: "Delete",
  Status: "Status",
  status: "Status",
  active: "Active",
  desCountry: "Destination Country",
  notification: "Notification",
  desState: "Destination State",
  services: "Services",
  back: "Back",
  save: "Save",
  viewDivisions: "View Divisions",
  clientMemberGroup: "Division Structure",
  clientContact: "Client Contacts",
  editClient: "Edit Client",
  deleteClient: "Delete Client",
  consultant: "Consultant",
  configTable: "Config Table",
  divisionStructure: "Division Structure",
};
