import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SupplierPortalService from "../../services/SupplierPortal/supplierPortal";

export const initialState: any = {
  stats: {
    data: {},
    error: null,
    loading: false,
  },
  vars: {
    limit: 10,
    page: 1,
    showFilter: false,
    query: "",
    status: "",
    city: "",
    sort: "",
  },
  request: {
    data: {},
    error: null,
    loading: false,
  },
};

export const supplierStats = createAsyncThunk(
  "SUPPLIER_DASHBOARD_STATS",
  async () => {
    const response = SupplierPortalService.supplierStats();
    return response;
  },
);

export const supplierRequest = createAsyncThunk(
  "SUPPLIER_REQUEST_LIST",
  async (data: any) => {
    const response = SupplierPortalService.supplierRequestList(data);
    return response;
  },
);

//create a slice for Client
const SupplierPortalSlice = createSlice({
  name: "supplierPortal",
  initialState,
  reducers: {
    reset: () => initialState,
    updateVars: (state, action) => {
      state.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    //get stats
    builder.addCase(supplierStats.pending, (state) => {
      state.stats.loading = true;
      state.stats.error = null;
      state.stats.data = {};
    });

    builder.addCase(supplierStats.fulfilled, (state, action: any) => {
      state.stats.loading = false;
      state.stats.error = null;
      state.stats.data = action.payload?.data;
    });

    builder.addCase(supplierStats.rejected, (state, action) => {
      state.stats.loading = false;
      state.stats.error = action?.error?.message;
      state.stats.data = {};
    });

    //get stats
    builder.addCase(supplierRequest.pending, (state) => {
      state.request.loading = true;
      state.request.error = null;
      state.request.data = {};
    });

    builder.addCase(supplierRequest.fulfilled, (state, action: any) => {
      state.request.loading = false;
      state.request.error = null;
      state.request.data = action.payload?.data;
    });

    builder.addCase(supplierRequest.rejected, (state, action) => {
      state.request.loading = false;
      state.request.error = action?.error?.message;
      state.request.data = {};
    });
  },
});

export default SupplierPortalSlice.reducer;
export const { updateVars, reset } = SupplierPortalSlice.actions;
