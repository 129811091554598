import {
  GET_EXCEPTION_LIST_URL,
  GET_RELOCATION_DETAILS_OF_TRANSFEREE,
  GET_CLIENT_CONTACT_URL,
  CREATE_EXCEPTION_URL,
  UPDATE_EXCEPTION_URL,
  GET_EXCEPTION_DETAILS_URL,
} from "../resources/constants";
import axios from ".";

export interface exceptionResponse {
  data?: { exceptionList: exceptionType[] };
  success?: boolean;
  error?: any;
}

interface exceptionType {
  id: string;
  version: string;
  clientContactId: string;
  comments: string;
  currentPolicyAllows: string;
  dateCreated: string;
  exceptionAmount: number;
  lastUpdated: string;
  osrRecommendations: string;
  reason: string;
  requestDetails: string;
  status: string;
  statusDate: string;
  transfereeId: string;
  transferee: any;
  emailList: any;
}

export default class exceptionServices {
  static async getExceptions(filtersData: any) {
    const result: any = await axios.get(
      `${GET_EXCEPTION_LIST_URL}?${new URLSearchParams(
        filtersData,
      ).toString()}`,
    );

    return result?.data;
  }

  static async getRequestExceptionsOfTransferee(filtersData: any) {
    const result: any = await axios.get(
      `${GET_EXCEPTION_LIST_URL}?transfereeId=${filtersData?.transfereeId}&limit=${filtersData?.limit}&page=${filtersData?.page}`,
    );

    return result?.data;
  }

  static async createException(exceptionDetails: any) {
    let result: any = {};
    let isEdit = exceptionDetails.exceptionData?.isEdit;
    delete exceptionDetails.exceptionData?.isEdit;
    if (isEdit)
      result = await axios.post(UPDATE_EXCEPTION_URL, exceptionDetails);
    else result = await axios.put(CREATE_EXCEPTION_URL, exceptionDetails);
    return result;
  }

  static async addException() {
    const result = await axios.get(GET_EXCEPTION_LIST_URL);
    return result;
  }

  static async getRelocationDetailsOfTransferee(transfereeId: string) {
    const result: any = await axios.get(
      `${GET_RELOCATION_DETAILS_OF_TRANSFEREE}/${transfereeId}`,
    );

    return result?.data?.relocationDetails;
  }

  static async getExceptionFormOfTransferee(transfereeId: string) {
    const result: any = await axios.get(
      `${GET_CLIENT_CONTACT_URL}/${transfereeId}`,
    );

    return { ...result?.data.transfereeData, ...result?.data.exceptionInfo };
  }

  //get exception details
  static async getException(id: string) {
    const result: any = await axios.get(`${GET_EXCEPTION_DETAILS_URL}/${id}`);
    return result?.data?.exceptionDetails;
  }

  public static async getAllExceptions(filtersData: any) {
    return await exceptionServices.getExceptions(filtersData);
  }

  public static async getRequestExceptions(transfereeId: string) {
    return await exceptionServices.getRequestExceptionsOfTransferee(
      transfereeId,
    );
  }

  public static async addNewException(filtersData: any) {
    return await exceptionServices.getExceptions(filtersData);
  }

  public static async getRelocationDetails(id: string) {
    return await exceptionServices.getRelocationDetailsOfTransferee(id);
  }

  public static async getExceptionForm(id: string) {
    return await exceptionServices.getExceptionFormOfTransferee(id);
  }

  //create exception
  public static async createExceptionService(transfereeId: string) {
    return await exceptionServices.createException(transfereeId);
  }

  //get exception details
  public static async getExceptionDetails(id: string) {
    return await exceptionServices.getException(id);
  }
}
