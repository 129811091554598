import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportsService from "../../services/Reports/reports";

export interface ReportsState {
  reportsData: {
    table: [];
    count: number;
    status: [];
    raw: any;
    loading: boolean;
  };
  favReportsData: {
    table: [];
    count: number;
    status: [];
    loading: boolean;
    isFavData: boolean;
  };
  favouriteList: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const initialState: ReportsState = {
  reportsData: {
    table: [],
    count: 0,
    raw: {},
    status: [],
    loading: false,
  },
  favReportsData: {
    table: [],
    count: 0,
    status: [],
    loading: false,
    isFavData: false,
  },
  favouriteList: {
    data: {},
    loading: false,
    error: null,
  },
};

export const getAllReports = createAsyncThunk(
  "getAllReportsReportsPage",
  async (filters: any) => {
    return ReportsService.getAllReports(filters);
  },
);

export const getClosedReports = createAsyncThunk(
  "getClosedReportsReportsPage",
  async (filters: any) => {
    return ReportsService.getClosedReports(filters);
  },
);

export const getFavData = createAsyncThunk(
  "getFavDataReportsPage",
  async (filters: any) => {
    return ReportsService.getFavData(filters);
  },
);

export const getInitiationReports = createAsyncThunk(
  "getInitiationReportsPage",
  async (filters: any) => {
    return ReportsService.getInitiationReports(filters);
  },
);
export const getExceptionReports = createAsyncThunk(
  "getExceptionReportsReportsPage",
  async (filters: any) => {
    return ReportsService.getExceptionReports(filters);
  },
);

export const getClientExpenseReports = createAsyncThunk(
  "getClientExpenseReportsReportsPage",
  async (filters: any) => {
    return ReportsService.getClientExpenseReports(filters);
  },
);

export const getTlrReports = createAsyncThunk(
  "getTlrReportsPage",
  async (filters: any) => {
    return ReportsService.getTlrReports(filters);
  },
);

export const getTloReports = createAsyncThunk(
  "getTloReportsPage",
  async (filters: any) => {
    return ReportsService.getTloReports(filters);
  },
);

export const getClientExpenseByCategory = createAsyncThunk(
  "getClientExpenseByCategoryReportsPage",
  async (filters: any) => {
    return ReportsService.getClientExpenseByCategory(filters);
  },
);

export const getSubmittedExepnse = createAsyncThunk(
  "getSubmittedExepnseReportsPage",
  async (filters: any) => {
    return ReportsService.getSubmittedExepnse(filters);
  },
);
export const getExceptionRequest = createAsyncThunk(
  "exceptionRequestReportsPage",
  async (filters: any) => {
    return ReportsService.getExceptionRequest(filters);
  },
);

export const getFavouriteList = createAsyncThunk(
  "getFavouriteList",
  async (data: any) => {
    return ReportsService.getFavouriteList(data);
  },
);

const getFavouriteListFullfilled = (state: any, action: any) => {
  state.favouriteList.data = action.payload?.data;
  state.favouriteList.loading = false;
  state.favouriteList.error = null;
};

const getFavouriteListPending = (state: any, action: any) => {
  state.favouriteList.data = {};
  state.favouriteList.loading = true;
  state.favouriteList.error = null;
};

const getFavouriteListRejected = (state: any, action: any) => {
  state.favouriteList.data = {};
  state.favouriteList.loading = false;
  state.favouriteList.error = action.error;
};

const slice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    clearData: (state) => {
      state.reportsData.table = [];
      state.reportsData.count = 0;
      state.reportsData.status = [];
    },
  },
  extraReducers(builder): void {
    builder.addCase(getFavouriteList.fulfilled, getFavouriteListFullfilled);
    builder.addCase(getFavouriteList.pending, getFavouriteListPending);
    builder.addCase(getFavouriteList.rejected, getFavouriteListRejected);

    builder.addCase(getFavData.fulfilled, (state, action: any) => {
      state.reportsData.table = action.payload?.data?.reportData || [];
      state.reportsData.loading = false;
    });

    builder.addMatcher(
      (action) => action.type.endsWith("ReportsPage/fulfilled"),
      (state, action) => {
        if (action.type.includes("getFavData")) {
          state.favReportsData.table = action.payload?.data?.reportData || [];
          state.favReportsData.count = action.payload?.data?.count || 0;
          state.favReportsData.status = action.payload?.status;
          state.favReportsData.loading = false;
          state.favReportsData.isFavData = true;
        } else {
          state.reportsData.table = action.payload?.data || [];
          state.reportsData.count = action.payload?.count || 0;
          state.reportsData.raw = action.payload || {};
          state.reportsData.status = action.payload?.status;
          state.reportsData.loading = false;
          state.favReportsData.isFavData = false;
        }
      },
    );
    builder.addMatcher(
      (action) => action.type.endsWith("ReportsPage/pending"),
      (state, action) => {
        state.reportsData.loading = true;
        state.reportsData.raw = {};
        state.reportsData.table = [];
        state.reportsData.count = 0;
        state.favReportsData.isFavData = false;
      },
    );
    builder.addMatcher(
      (action) => action.type.endsWith("ReportsPage/rejected"),
      (state, action) => {
        state.reportsData.loading = false;
        state.reportsData.raw = {};
        state.reportsData.table = [];
        state.reportsData.count = 0;
        state.favReportsData.isFavData = false;
      },
    );
  },
});

export const { clearData } = slice.actions;
export default slice.reducer;
