import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import error from "../../utils/error";

import WhatsNewService from "../../services/WhatsNew/WhatsNew";

const initialState: any = {
  whatsNew: {
    data: {},
    error: null,
    loading: false,
  },
};

export const getWhatsNew = createAsyncThunk("GET_WHATS_NEW", async () => {
  const response = WhatsNewService.getWhatsNew();
  return response;
});

//create a slice for Client
const WhatsNewSlice = createSlice({
  name: "whatsNew",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    //get whatsNew
    builder.addCase(getWhatsNew.pending, (state) => {
      state.whatsNew.loading = true;
      state.whatsNew.error = null;
      state.whatsNew.data = {};
    });

    builder.addCase(getWhatsNew.fulfilled, (state, action: any) => {
      state.whatsNew.loading = false;
      state.whatsNew.error = null;
      state.whatsNew.data = action.payload?.data;
    });

    builder.addCase(getWhatsNew.rejected, (state, action) => {
      state.whatsNew.loading = false;
      state.whatsNew.error = error(action.payload);
      state.whatsNew.data = {};
    });
  },
});

export default WhatsNewSlice.reducer;
