import axios from "..";
import { GET_COST_ESTIMATES_URL } from "../../resources/constants";

interface getCostEstimatesListing extends Record<string, any> {
  page: number;
  limit: number;
  order: string;
  search: string;
  draft: string;
  client?: string;
}

class costEstimatesListingService {
  public static async getCostEstimatesListing(params: getCostEstimatesListing) {
    return await axios.get(
      GET_COST_ESTIMATES_URL + "/?" + new URLSearchParams(params).toString(),
    );
  }
}

export type { getCostEstimatesListing };
export default costEstimatesListingService;
