import axios from "..";
import {
  GET_COST_ESTIMATES_SHIPPING_TOTAL,
  GET_COST_ESTIMATES_DEFAULT_CONFIG,
  GET_COST_ESTIMATES_APPROVERS,
  CREATE_COST_ESTIMATES_PDF,
  CREATE_COST_ESTIMATES_APPROVERS,
  CREATE_COST_ESTIMATES,
  UPDATE_COST_ESTIMATES,
  COPY_COST_ESTIMATES,
  UPDATE_APPROVAL,
  REQUEST_COST_ESTIMATE,
  LINK_COST_ESTIMATE,
} from "../../resources/constants";
import Search from "antd/es/transfer/search";
import { createSearchParams } from "react-router-dom";

class costEstimatesService {
  public static async getShippingTotal(data: any) {
    return await axios.post(GET_COST_ESTIMATES_SHIPPING_TOTAL, data);
  }

  public static async getDefaultConfig(data: any) {
    return await axios.get(
      GET_COST_ESTIMATES_DEFAULT_CONFIG +
        "/?" +
        new URLSearchParams(data).toString(),
    );
  }

  public static async getApprovers(data: any) {
    return await axios.get(
      GET_COST_ESTIMATES_APPROVERS + "?" + new URLSearchParams(data).toString(),
    );
  }

  public static async createApprovers(data: any) {
    return await axios.post(
      CREATE_COST_ESTIMATES_APPROVERS +
        "/?sendEmail=" +
        data.sendEmail +
        "&worksheetId=" +
        data.worksheetId,
      data.approvers,
    );
  }

  public static async createPDF(id: string) {
    const headers = {
      responseType: "blob",
    };
    return axios.get<any>(CREATE_COST_ESTIMATES_PDF + "/" + id, headers);
  }

  public static async updateEstimates(data: any) {
    return await axios.put(UPDATE_COST_ESTIMATES + "/" + data.id, data);
  }

  public static async createEstimates(data: any) {
    return await axios.post(CREATE_COST_ESTIMATES, data);
  }

  public static async copyEstimates(id: string) {
    return await axios.get(COPY_COST_ESTIMATES + "/" + id);
  }

  public static async updateApproval(data: any) {
    return await axios.put(UPDATE_APPROVAL + "/" + data?.id, data);
  }

  public static async requestEstimate(data: any) {
    return await axios.post(REQUEST_COST_ESTIMATE, data);
  }

  public static async linkEstimate(data: any) {
    return await axios.post(LINK_COST_ESTIMATE, data);
  }
}

export default costEstimatesService;
