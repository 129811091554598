import React from "react";
import { Image, ImageProps } from "antd";

interface Props extends ImageProps {
  alt?: string;
  className?: string;
}

const ImageComp: React.FC<Props> = ({ alt, className, ...rest }) => {
  return <Image preview={false} className={className} alt={alt} {...rest} />;
};

export default ImageComp;
