import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = true;

//create a slice for Client
const authorizedSlice = createSlice({
  name: "authorized",
  initialState,
  reducers: {
    setAuthorized: (state: any, action: any) => {
      return action.payload;
    },
  },
});

export default authorizedSlice.reducer;
export const setAuthorized: any = authorizedSlice.actions.setAuthorized;
