import React, { useEffect, useRef } from "react";
import "./loader.scss";
import { useSelector } from "react-redux";
import Lottie from 'react-lottie';
import animationData from "../../resources/loaders/cri-loader.json";

const Loader = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [text, setText] = React.useState("Loading...");

  const showProgress = useSelector((state) => state.loader.showProgress);
  const showProgressText = useSelector((state) => state.loader.showProgressText);

  useEffect(() => {
    const timerOne = setTimeout(() => {
      setText("Processing your request...");
    }, 1000);

    const timerTwo = setTimeout(() => {
      setText("Please Wait, processing your request...");
    }, 5000);

    const timerThree = setTimeout(() => {
      setText("Almost done...");
    }, 10000);

    return () => {
      clearTimeout(timerOne);
      clearTimeout(timerTwo);
      clearTimeout(timerThree);
    }
  }, []);



  const logoWidth = 300;

  return (
    <div className="loaderTemp">

      <Lottie
        options={defaultOptions}
        width={logoWidth}
        height={logoWidth * 9 / 16}
        isClickToPauseDisabled={true}
      />

      {showProgress &&
        <div className="loadData">
          <div class="linear-activity">
            <div class="indeterminate"></div>
          </div>
        </div>
      }

      {showProgressText && <div className="progressText">{text}</div>}

    </div>
  );
};

export default Loader;
