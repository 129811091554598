import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./ContactSupport.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import dayjs from "dayjs";
import { Notify } from "../../components/Notification/Toast";
import { setLoading } from "../../store/Loader/slice";
import pdfFile from "../../resources/images/pdf-file.svg";
import contactSuccess from "../../resources/images/contact-success.svg";
import ClientServices from "../../services/Client/client";

const ContactSupport = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state?.login);
  const [userName, setUserName] = useState("");

  const initFormValues = {
    title: "Question for support at Corporate Relocation International!",
    message: "",
    type: "General Question",
    severity: "Medium",
    dateReported: new Date().toISOString(),
  };
  const [formValues, setFormValues] = useState<any>(initFormValues);
  const [submittable, setSubmittable] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [supportModal, setSupportModal] = useState(true);

  useEffect(() => {
    setSupportModal(true);
    setUpdateModal(false);
    setFormValues(initFormValues);
  }, []);

  useEffect(() => {
    if (user?.user?.firstName && user?.user?.lastName) {
      setUserName(`${user?.user?.firstName} ${user?.user?.lastName}`);
    }
  }, [user]);

  useEffect(() => {
    updateTitle(formValues.type);
  }, [userName]);

  const updateTitle = (value: string) => {
    userName &&
      setFormValues({
        ...formValues,
        title: getTitle(value),
      });
  };

  const getTitle = (value: string) => {
    return value === "General Question"
      ? `Question from ${userName} for support at Corporate Relocation International!`
      : `Relo Portal Bug Report from ${userName}`;
  };

  useEffect(() => {
    if (formValues.title && formValues.message) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  }, [formValues]);

  const onFinish = () => {
    dispatch(setLoading(true));
    const formData = new FormData();

    if (logoFile?.name) {
      formData.append("file", logoFile);
    }

    const client = {
      ...formValues,
    };

    formData.append("reportIssue", JSON.stringify(client));

    ClientServices.contactSupport(formData)
      .then((res) => {
        setUpdateModal(true);
        setSupportModal(false);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleClick = () => {
    const fileInput = document.querySelector(
      'input[name="uploadfile"]',
    ) as HTMLInputElement;
    fileInput?.click();
  };

  const [logoFile, setLogoFile] = useState<any>(null);

  const fileChange = (e: any) => {
    Array(e.target.files.length)
      .fill(0)
      .map((_, index: any) => {
        const file = e.target.files[index];
        if (file.size > 1024 * 1024 * 8) {
          Notify.error("Max file upload size is 8MB");
        }
        if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
          Notify.error("File type not allowed. Only png, jpg, jpeg allowed.");
        } else {
          setLogoFile(file);
        }
      });

    setTimeout(() => {
      const fileInput = document.querySelectorAll(
        'input[name="uploadfile"]',
      ) as NodeListOf<HTMLInputElement>;
      fileInput.forEach((item) => (item.value = ""));
    }, 300);
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
    e.target.closest(".dropAreaC").classList.add("drag");
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
    e.target.closest(".dropAreaC").classList.remove("drag");
  };

  const dragOver = (e: any) => {
    e.preventDefault();
    e.target.closest(".dropAreaC").classList.add("drag");
  };

  const drop = (e: any) => {
    e.preventDefault();
    e.target.closest(".dropAreaC").classList.remove("drag");

    let dt = e.dataTransfer;
    let files = dt.files;

    fileChange({ target: { files } as any });
  };

  useEffect(() => {
    const dropAreaC = document.querySelector(".dropAreaC") as HTMLElement;

    dropAreaC.addEventListener("dragenter", dragEnter, false);
    dropAreaC.addEventListener("dragleave", dragLeave, false);
    dropAreaC.addEventListener("dragover", dragOver, false);
    dropAreaC.addEventListener("drop", drop, false);

    return () => {
      dropAreaC.removeEventListener("dragenter", dragEnter, false);
      dropAreaC.removeEventListener("dragleave", dragLeave, false);
      dropAreaC.removeEventListener("dragover", dragOver, false);
      dropAreaC.removeEventListener("drop", drop, false);
    };
  }, []);

  const deleteLogoFile = () => {
    setLogoFile(null);
  };

  const handleUpdateOk = () => {
    setUpdateModal(false);
    setSupportModal(false);
    props.setOpenContactSupport(false);
  };

  return (
    <>
      <Modal
        open={supportModal}
        onCancel={handleUpdateOk}
        className="createNewFolderModal contactSupport"
        style={{ maxWidth: "90%" }}
        width={800}
        footer={[
          <Button
            id="cancelBtn"
            key="submit"
            className="btnRed light"
            type="primary"
            onClick={handleUpdateOk}
          >
            Cancel
          </Button>,

          <Button
            id="sendBtn"
            key="submit"
            className="btnBlue"
            type="primary"
            onClick={onFinish}
            disabled={!submittable}
          >
            Send Issue
          </Button>,
        ]}
      >
        <Row justify="space-around" align="middle" gutter={20}>
          <h2 className="contactSH2">Contact Support</h2>
          <Col md={24}>
            <div className="stdForm">
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={12}>
                  <p className="label">Issue Type*</p>
                  <Select
                    id="issueTypeSelect"
                    placeholder="Select Type"
                    className="stdSelect"
                    value={formValues.type}
                    options={[
                      { value: "General Question" },
                      { value: "Report a Bug" },
                    ]}
                    onChange={(value) => {
                      setFormValues({
                        ...formValues,
                        type: value,
                        title: getTitle(value),
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <p className="label">Issue Date</p>
                  <DatePicker
                    id="issueDateSelect"
                    placeholder={"select Issue Date"}
                    defaultValue={dayjs(new Date())}
                    format={"MMM DD, YYYY"}
                    allowClear
                    onChange={(date, dateString) => {
                      setFormValues({
                        ...formValues,
                        dateReported: dateString,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <p className="label">Title*</p>
                  <Input
                    id="titleInput"
                    type={"text"}
                    placeholder="Enter Title"
                    value={formValues.title}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        title: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <p className="label">Message*</p>
                  <Input.TextArea
                    id="messageInput"
                    rows={2}
                    showCount
                    maxLength={500}
                    placeholder="Enter Message"
                    value={formValues.message}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        message: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={12}>
                      <p className="label">Attach Issue Screenshot/Image</p>
                      <div
                        id="uploadBtn"
                        className="dropAreaC uploadDrag stdSTop"
                        onClick={handleClick}
                      >
                        <PlusOutlined />
                        <div>Upload</div>
                      </div>
                      <input
                        id="uploadFileInput"
                        style={{ display: "none" }}
                        type="file"
                        onInput={fileChange}
                        name="uploadfile"
                        accept=".png,.jpg,.jpeg"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div
                        id="progressListDiv"
                        className="progressList"
                        style={{ maxWidth: "500px" }}
                      >
                        {logoFile?.name && (
                          <div>
                            <img src={pdfFile} />
                            <div>{logoFile?.name || "-"}</div>
                            <span
                              id="deleteProgressBtn"
                              onClick={deleteLogoFile}
                              className="deleteProgress"
                            >
                              <DeleteOutlined />
                            </span>
                          </div>
                        )}

                        {}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <p className="label">Issue Severity*</p>
                  <Select
                    id="severitySelect"
                    placeholder="Select Issue Severity"
                    className="stdSelect"
                    options={[
                      { value: "Critical" },
                      { value: "High" },
                      { value: "Medium" },
                      { value: "Low" },
                      { value: "Usability" },
                    ]}
                    value={formValues.severity}
                    onChange={(value) => {
                      setFormValues({
                        ...formValues,
                        severity: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <div className="br20"></div>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        className="centerModal"
        open={updateModal}
        onOk={handleUpdateOk}
        onCancel={handleUpdateOk}
        footer={[
          <div></div>,
          <Button
            id="doneBtn"
            key="submit"
            className="btnBlue"
            type="primary"
            onClick={handleUpdateOk}
          >
            Done
          </Button>,
        ]}
      >
        <img src={contactSuccess} alt="success modal" />
        <h3>Support Request was successfully sent!</h3>
      </Modal>
    </>
  );
};
export default ContactSupport;
