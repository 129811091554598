import { Crypt, RSA } from "hybrid-crypto-js";
const rsa = new RSA();
const crypt = new Crypt();

export const generateKeys = () => {
  return rsa.generateKeyPairAsync().then((keyPair: any) => {
    return {
      publicKey: keyPair.publicKey,
      privateKey: keyPair.privateKey,
    };
  });
};

export const encrypt = (publicKey: string, data: any) => {
  return crypt.encrypt(publicKey, data);
};

export const decrypt = (privateKey: string, data: any) => {
  return crypt.decrypt(privateKey, data);
};
