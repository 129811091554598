import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import addEmployeeService from "../../services/add-employee";
import {
  ACTION_AUTHORIZED_DIVISION_LISTING,
  ACTION_DIVISION_LISTING,
  ACTION_GET_CITY,
  ACTION_GET_COUNTRY,
  ACTION_GET_STATE,
} from "../../Actions/actions";
import divisionsServices from "../../services/Divisions/divisions";
import transfereeProfileService from "../../services/TransfereeProfile/TransfereeProfile";

export interface AddNewEmployee {
  data: any;
  policy: {
    data: any;
    error: any;
    loading: boolean;
  };
  divisionConfig: {
    data: any;
    error: any;
    loading: boolean;
  };
  employeeStore: any;
  allDivisions: [];
  countries: [];
  states: {};
  cities: {};
  statusList: any;
  transfereeDetails: any;
  searchedTransferees: any;
  miloTransfereeDetails: any;
  miloTransfereeDetailsError: any;
  vars: any;
}

const initialState: AddNewEmployee = {
  data: {},
  policy: {
    data: [],
    error: "",
    loading: false,
  },
  divisionConfig: {
    data: [],
    error: "",
    loading: false,
  },
  employeeStore: {
    relocation_details: {},
    transferee_details: {},
    user: {},
    status: {},
  },
  allDivisions: [],
  countries: [],
  statusList: {},
  states: { origin: [], destination: [] },
  cities: { origin: [], destination: [] },
  transfereeDetails: null,
  searchedTransferees: {
    loading: false,
    error: null,
    table: [],
    count: 0,
  },
  miloTransfereeDetails: {},
  miloTransfereeDetailsError: null,
  vars: {
    limit: 10,
    currentPage: 1,
    fileNumber: "",
  },
};

export const getPolicyByDivisionId = createAsyncThunk(
  "policyByDivId",
  async (payload: any) => {
    return addEmployeeService.getPolicyByDivisionId(payload);
  },
);

export const searchMiloEmployee = createAsyncThunk(
  "searchMiloEmployee",
  async (payload: any) => {
    return addEmployeeService.searchMiloEmployee(payload);
  },
);

export const getMiloTransfereeDetails = createAsyncThunk(
  "getMiloTransfereeDetails",
  async (payload: any) => {
    return addEmployeeService.getMiloTransfereeDetails(payload);
  },
);

export const getCountriesWithType = createAsyncThunk(
  ACTION_GET_COUNTRY,
  async (type: string) => {
    return addEmployeeService.getCountriesWithType(type);
  },
);

export const getStatesWithType = createAsyncThunk(
  ACTION_GET_STATE,
  async ({ countryId, type }: { countryId: string; type: string }) => {
    return addEmployeeService.getStatesWithType(countryId, type);
  },
);

export const getCitiesWithType = createAsyncThunk(
  ACTION_GET_CITY,
  async ({
    countryId,
    stateId,
    type,
  }: {
    countryId: string;
    stateId: string;
    type: string;
  }) => {
    return addEmployeeService.getCitiesWithType(countryId, stateId, type);
  },
);

export const getAllDivisionsAndId = createAsyncThunk(
  ACTION_DIVISION_LISTING,
  async (params: {
    clientId: string;
    divisionId: string;
    direction: string;
  }) => {
    return divisionsServices.getDivisionsAndId(
      params.clientId,
      params.divisionId,
      params.direction,
    );
  },
);

export const getAuthorizedDivisions = createAsyncThunk(
  ACTION_AUTHORIZED_DIVISION_LISTING,
  async (payload: any) => {
    return divisionsServices.getAuthorizedDivisions(payload);
  },
);

export const getDivisionConfig = createAsyncThunk(
  "getDivisionConfig",
  async ({
    divisionId,
    clientId,
    active,
  }: {
    divisionId?: string;
    clientId: string;
    active: boolean;
  }) => {
    return addEmployeeService.getDivisionConfig(clientId, active, divisionId);
  },
);

export const getStatusList = createAsyncThunk(
  "getStatusList",
  async (data: any) => {
    return addEmployeeService.getStatusList(data);
  },
);

export const getTransfereeDetails = createAsyncThunk(
  "addEmployee/getTransferee",
  async (id: string) => {
    return transfereeProfileService.getTransfereeDetails(id);
  },
);

export const createEmployee = createAsyncThunk(
  "createEmployee",
  async (payload: any) => {
    return addEmployeeService.createEmployee(payload);
  },
);

const getPolicyByDivisionIdFullFilled = (state: any, action: any) => {
  state.loading = false;
  state.policy.data = action.payload;
};

const getPolicyByDivisionIdPending = (state: any, action: any) => {
  state.policy.loading = true;
  state.policy.error = action.error;
};

const getPolicyByDivisionIdRejected = (state: any, action: any) => {
  state.policy.loading = false;
  state.policy.data = [];
  state.policy.error = action.error;
};

const getDivisionConfigFullfilled = (state: any, action: any) => {
  state.loading = false;
  state.divisionConfig.data = action.payload;
};

const getDivisionByIdFullfilled = (state: any, action: any) => {
  state.loading = false;
  const index = state.allDivisions.findIndex(
    (division: any) => division.id === action.payload.id,
  );
  if (index !== -1) {
    state.allDivisions[index] = action.payload;
  } else {
    state.allDivisions = [action.payload];
  }
};

const getCountriesWithTypeFullFilled = (state: any, action: any) => {
  state.countries = action.payload?.data.countries;
};

const getStatesWithTypeFullFilled = (state: any, action: any) => {
  state.states[action.payload.type] = action.payload?.data;
};

const getCitiesWithTypeFullFilled = (state: any, action: any) => {
  state.cities[action.payload.type] = action.payload?.data;
};

const getStatusListFullFilled = (state: any, action: any) => {
  state.statusList = action.payload;
};

const getTransfereeFullFilled = (state: any, action: any) => {
  state.transfereeDetails = action.payload?.data;
};

const getSearchedEmployeeFullFilled = (state: any, action: any) => {
  state.searchedTransferees.loading = false;
  state.searchedTransferees.table = action.payload.transferees;
  state.searchedTransferees.count = action.payload.transfereeCount;
};

const getSearchedEmployeePending = (state: any, action: any) => {
  state.searchedTransferees.loading = true;
};

const getSearchedEmployeeRejected = (state: any, action: any) => {
  state.searchedTransferees.loading = false;
  state.searchedTransferees.table = [];
  state.searchedTransferees.error = action.error;
};

const getMiloTransfereeDetailsFullFilled = (state: any, action: any) => {
  state.miloTransfereeDetails = action.payload?.data.transferee;
};

const getMiloTransfereeDetailsPending = (state: any, action: any) => {
  state.miloTransfereeDetails = {};
};

const getMiloTransfereeDetailsRejected = (state: any, action: any) => {
  state.miloTransfereeDetails = {};
};

const addEmployeeSlice = createSlice({
  name: "divisions",
  initialState,
  reducers: {
    updateEmployeeStore(state, action) {
      state.employeeStore = action.payload;
    },
    resetEmployeeStore: () => initialState,
    clearEmployeeStore(state) {
      state.transfereeDetails = null;
      state.allDivisions = [];
      state.employeeStore = {
        relocation_details: {},
        transferee_details: {},
        user: {},
        status: {},
        policy: {},
      };
    },
    updateAllDivisions(state, action) {
      state.allDivisions = action.payload;
    },
    updateVars(state, action) {
      state.vars = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(
      getPolicyByDivisionId.fulfilled,
      getPolicyByDivisionIdFullFilled,
    );
    builder.addCase(
      getPolicyByDivisionId.pending,
      getPolicyByDivisionIdPending,
    );
    builder.addCase(
      getPolicyByDivisionId.rejected,
      getPolicyByDivisionIdRejected,
    );

    builder.addCase(getDivisionConfig.fulfilled, getDivisionConfigFullfilled);
    builder.addCase(getDivisionConfig.pending, getPolicyByDivisionIdPending);
    builder.addCase(getDivisionConfig.rejected, getPolicyByDivisionIdRejected);

    builder.addCase(getAllDivisionsAndId.fulfilled, getDivisionByIdFullfilled);
    builder.addCase(getAllDivisionsAndId.pending, getPolicyByDivisionIdPending);
    builder.addCase(
      getAllDivisionsAndId.rejected,
      getPolicyByDivisionIdRejected,
    );
    builder.addCase(
      getAuthorizedDivisions.fulfilled,
      getDivisionByIdFullfilled,
    );

    builder.addCase(
      getCountriesWithType.fulfilled,
      getCountriesWithTypeFullFilled,
    );
    builder.addCase(getStatesWithType.fulfilled, getStatesWithTypeFullFilled);
    builder.addCase(getCitiesWithType.fulfilled, getCitiesWithTypeFullFilled);

    builder.addCase(getStatusList.fulfilled, getStatusListFullFilled);

    builder.addCase(getTransfereeDetails.fulfilled, getTransfereeFullFilled);
    builder.addCase(
      searchMiloEmployee.fulfilled,
      getSearchedEmployeeFullFilled,
    );
    builder.addCase(searchMiloEmployee.pending, getSearchedEmployeePending);
    builder.addCase(searchMiloEmployee.rejected, getSearchedEmployeeRejected);
    //getMiloTransfereeDetails
    builder.addCase(
      getMiloTransfereeDetails.fulfilled,
      getMiloTransfereeDetailsFullFilled,
    );
    builder.addCase(
      getMiloTransfereeDetails.pending,
      getMiloTransfereeDetailsPending,
    );
    builder.addCase(
      getMiloTransfereeDetails.rejected,
      getMiloTransfereeDetailsRejected,
    );
  },
});

export const {
  updateEmployeeStore,
  clearEmployeeStore,
  updateAllDivisions,
  resetEmployeeStore,
  updateVars,
} = addEmployeeSlice.actions;

export default addEmployeeSlice.reducer;
